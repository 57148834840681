import { createGlobalStyle } from 'styled-components';
import { breakpoints } from '@/styles/breakpoints';

const GlobalStyle = createGlobalStyle`
  :root {
    scroll-behavior: auto !important;
  }
  
  .animatedLineParent {
    overflow: hidden;
  }

  .animatedLineChild {
    display: inline-block;
    opacity: 0;
  }
  
  @font-face {
    font-family: 'PPFormula';
    src: url('/fonts/PPFormula/PPFormula-CondensedBlack.woff') format('woff');
    font-style: normal;
    font-weight: 800;
    //font-display: swap;
    font-display: optional;
  }
  
  img {
    pointer-events: none;
  }

  html, body {
    background-color: ${({ theme }) => theme.colors.bg};
    font-family: ${({ theme }) => theme.fonts.families.body};
    color: ${({ theme }) => theme.colors.text.light};
    position: relative;

    * > ::-webkit-scrollbar {
      width: 2px;
    }

    * > ::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.colors.primary.first};
    }

    * > ::-webkit-scrollbar-track {
      background-color: rgba(232, 81, 67, 0.3);
    }
  }

  body, p {
    font-weight: ${({ theme }) => theme.fonts.weights.regular};
    font-size: ${({ theme }) => theme.fonts.sizes.mobile.regular};

    ${breakpoints.MD} {
      font-size: ${({ theme }) => theme.fonts.sizes.tablet.regular};
    }

    ${breakpoints.MDL} {
      font-size: ${({ theme }) => theme.fonts.sizes.tabletLandscape.regular};
    }

    ${breakpoints.LG} {
      font-size: ${({ theme }) => theme.fonts.sizes.desktop.regular};
    }
  }

  p {
    letter-spacing: 0.002em;
    line-height: ${({ theme }) => theme.fonts.lineHeights.xxLarge};

    ${breakpoints.MD} {
      line-height: ${({ theme }) => theme.fonts.lineHeights.xLarge};
    }

    ${breakpoints.XXLG} {
      font-size: 18px;
      line-height: 24px;
    }
  }

  h1,h2,h3 {
    font-family: ${({ theme }) => theme.fonts.families.title};
    text-transform: uppercase;
    font-weight: 800;
  }

  h1 {
    letter-spacing: -0.01em;
    line-height: ${({ theme }) => theme.fonts.lineHeights.regular};
    font-size: ${({ theme }) => theme.fonts.sizes.mobile.xxxLarge};

    ${breakpoints.MD} {
      font-size: ${({ theme }) => theme.fonts.sizes.tablet.xxxLarge};
    }

    ${breakpoints.MDL} {
      font-size: ${({ theme }) => theme.fonts.sizes.tabletLandscape.xxxLarge};
    }

    ${breakpoints.LG} {
      font-size: ${({ theme }) => theme.fonts.sizes.desktop.xxxLarge};
    }
  }

  h2 {
    line-height: ${({ theme }) => theme.fonts.lineHeights.less};
    font-size: ${({ theme }) => theme.fonts.sizes.mobile.xxLarge};

    ${breakpoints.MD} {
      line-height: ${({ theme }) => theme.fonts.lineHeights.semiSmall};
      font-size: ${({ theme }) => theme.fonts.sizes.tablet.xxLarge};
    }

    ${breakpoints.MDL} {
      line-height: ${({ theme }) => theme.fonts.lineHeights.small};
      font-size: ${({ theme }) => theme.fonts.sizes.tabletLandscape.xxLarge};
    }

    ${breakpoints.LG} {
      line-height: ${({ theme }) => theme.fonts.lineHeights.small};
      font-size: ${({ theme }) => theme.fonts.sizes.desktop.xxLarge};
    }

    ${breakpoints.XXLG} {
      font-size: 196px;
      line-height: 180px;
    }
  }

  h3 {
    letter-spacing: 0.01em;
    line-height: ${({ theme }) => theme.fonts.lineHeights.small};
    font-size: ${({ theme }) => theme.fonts.sizes.mobile.xLarge};

    ${breakpoints.MD} {
      line-height: ${({ theme }) => theme.fonts.lineHeights.extraSmall};
      font-size: ${({ theme }) => theme.fonts.sizes.tablet.xLarge};
    }

    ${breakpoints.MDL} {
      font-size: ${({ theme }) => theme.fonts.sizes.tabletLandscape.xLarge};
    }

    ${breakpoints.LG} {
      font-size: ${({ theme }) => theme.fonts.sizes.desktop.xLarge};
      line-height: ${({ theme }) => theme.fonts.lineHeights.superSmall};
    }

    ${breakpoints.XXLG} {
      font-size: 108px;
      line-height: 92px;
    }
  }

  h4,h5,h6, .subtitle {
    font-family: ${({ theme }) => theme.fonts.families.body};
    font-weight: ${({ theme }) => theme.fonts.weights.medium};
    line-height: ${({ theme }) => theme.fonts.lineHeights.large};
    font-size: ${({ theme }) => theme.fonts.sizes.mobile.large};
    letter-spacing: 0.002em;
    text-transform: none;
    
    &:not(:last-child) {
      margin-bottom: ${({ theme }) => theme.spacings.x2};
    }

    ${breakpoints.MD} {
      font-size: ${({ theme }) => theme.fonts.sizes.tablet.large};
    }

    ${breakpoints.MDL} {
      font-size: ${({ theme }) => theme.fonts.sizes.tabletLandscape.large};
    }

    ${breakpoints.LG} {
      font-size: ${({ theme }) => theme.fonts.sizes.desktop.large};
    }

    ${breakpoints.XXLG} {
      font-size: ${({ theme }) => theme.fonts.sizes.k2.large};

      &:not(:last-child) {
        margin-bottom: ${({ theme }) => theme.spacings.x4};
      }
    }
  }

  button, .button {
    font-family: ${({ theme }) => theme.fonts.families.body};
    font-weight: ${({ theme }) => theme.fonts.weights.bold};
    color: ${({ theme }) => theme.colors.text.light};
    font-size: ${({ theme }) => theme.fonts.sizes.mobile.medium};
    line-height: ${({ theme }) => theme.fonts.lineHeights.semiLarge};
    text-transform: uppercase;
    letter-spacing: 0.2px;
    background-color: transparent;
    box-shadow: none;
    border-radius: 0;
    border: none;
    padding: 0;
    margin: 0;
    transition: color 0.1s ease-in-out;

    &:hover {
      color: ${({ theme }) => theme.colors.text.grey};
    }

    &:active {
      color: ${({ theme }) => theme.colors.primary.first};
    }

    ${breakpoints.MD} {
      font-size: ${({ theme }) => theme.fonts.sizes.tablet.medium};
      line-height: ${({ theme }) => theme.fonts.lineHeights.medium};
    }

    ${breakpoints.MDL} {
      font-size: ${({ theme }) => theme.fonts.sizes.tabletLandscape.medium};
    }

    ${breakpoints.LG} {
      font-size: ${({ theme }) => theme.fonts.sizes.desktop.medium};
    }
  }

  a, .link {
    color: ${({ theme }) => theme.colors.text.light};
    font-size: ${({ theme }) => theme.fonts.sizes.mobile.small};
    line-height: ${({ theme }) => theme.fonts.lineHeights.xxLarge};
    letter-spacing: 0.002em;
    text-transform: uppercase;
    text-decoration: none;
    transition: color 0.1s ease-in-out;
    
    &:hover {
      color: ${({ theme }) => theme.colors.text.grey};
    }
    
    &:active {
      color: ${({ theme }) => theme.colors.primary.first};
    }
  }

  .text-small {
    letter-spacing: 0.002em;
    line-height: ${({ theme }) => theme.fonts.lineHeights.regular};
    font-size: ${({ theme }) => theme.fonts.sizes.mobile.small};
  }

  .text-super-small {
    letter-spacing: 0.002em;
    font-size: ${({ theme }) => theme.fonts.sizes.desktop.superSmall};
    line-height: ${({ theme }) => theme.fonts.lineHeights.xxxLarge};

    ${breakpoints.XXLG} {
      font-size: ${({ theme }) => theme.fonts.sizes.k2.superSmall};
      line-height: 1.29;
    }
  }
  
  .text-branded, strong {
    color: ${({ theme }) => theme.colors.primary.first};
    font-weight: inherit;
  }
  
  .text-dark {
    color: ${({ theme }) => theme.colors.text.dark};
  }
  
  .container {
    margin: 0 auto;
    padding: 0 ${({ theme }) => parseInt(theme.spacings.x2) + 2}px;
    
    ${breakpoints.MD} {
      padding: 0 ${({ theme }) => theme.spacings.x5};
    }

    ${breakpoints.MDL} {
      padding: 0 ${({ theme }) => parseInt(theme.spacings.x7) + 6}px;
    }

    ${breakpoints.LG} {
      padding: 0 ${({ theme }) => parseInt(theme.spacings.x7) + 6}px;
    }

    ${breakpoints.XXLG} {
      padding: 0 120px;
    }
  }
`;

export { GlobalStyle };
