import {
  Actions,
  AttributeContent,
  AttributeLabel,
  Attributes,
  Content,
  Grid,
  RarityScore,
  StoryImage,
  StyledStoryModal,
  Title,
  TitleWrapper,
  Token,
} from '@/components/Story/Modal/styles';
import { IStoriesStore } from '@/stores/Stories';
import { FC, useEffect, useRef, useState } from 'react';
import { inject, observer } from 'mobx-react';
import DiamondSVG from '@/assets/diamond.svg';
import Badge from '@/components/Badge';
import Image from 'next/image';
import Button from '@/components/UI/Button';
import { IUIStore } from '@/stores/UI';

interface StoryModalProps {
  slug: string;
  storiesStore: IStoriesStore;
  uiStore: IUIStore;
}

const StoryModal: FC<any> = ({
  slug,
  storiesStore,
  uiStore: { openModal },
}: StoryModalProps) => {
  const titleRef = useRef(null);
  const [loaded, setLoaded] = useState(false);
  const [height, setHeight] = useState('');

  let story = null;

  if (slug === 'preview') {
    story = storiesStore.preview;
  } else {
    story = storiesStore.items.get(slug);
  }

  const createStory = storiesStore.createStory;

  const resize = () => {
    setHeight(
      `calc(100vh - 311px - 16px - ${titleRef.current.offsetHeight}px - 4px - 15px - 16px - 21px - 16px - 72px - 94px)`,
    );
  };

  useEffect(() => {
    setLoaded(true);
  }, []);

  useEffect(() => {
    if (!loaded) return;
    resize();

    window.addEventListener('resize', resize);

    return () => {
      window.removeEventListener('resize', resize);
    };
  }, [loaded]);

  const viewBear = async (e) => {
    e.preventDefault();

    await openModal({ type: 'bear', instance: story.bear.identifier });

    return window.history.pushState(
      { scroll: false },
      '',
      `/bear/${story.bear.identifier}`,
    );
  };

  const editStory = () => {
    openModal({ type: 'createStory', instance: 'preview' });
  };

  return (
    <StyledStoryModal>
      <Grid>
        <StoryImage>
          <Image
            src={story.bear.image}
            layout="fill"
            alt={story.bearName.toString()}
          />
        </StoryImage>
        <TitleWrapper>
          <Title ref={titleRef}>{story.title}</Title>
          <Token>
            <Badge secondary>#{story.bear.tokenId}</Badge>
          </Token>
        </TitleWrapper>
        <RarityScore>
          <span>Rarity Score</span>
          <Badge icon={<DiamondSVG viewBox="0 0 11 11" />}>
            {story.bear.rarityScore}
          </Badge>
        </RarityScore>
        <Content style={{ height }}>
          <Attributes>
            <div>
              <AttributeLabel>Name</AttributeLabel>
              <AttributeContent>{story.bearName}</AttributeContent>
            </div>
            <div>
              <AttributeLabel>Age</AttributeLabel>
              <AttributeContent>{story.bearAge}</AttributeContent>
            </div>
            <div>
              <AttributeLabel>Story</AttributeLabel>
              <AttributeContent>
                {story.story
                  .split('\n')
                  .filter((i) => i.length)
                  .map((text, index) => (
                    <p key={index.toString()}>{text}</p>
                  ))}
              </AttributeContent>
            </div>
          </Attributes>
        </Content>
        <Actions>
          {slug === 'preview' && (
            <>
              <Button type="secondary" colored onClick={createStory}>
                Post the story
              </Button>
              <Button type="secondary" onClick={editStory}>
                Edit
              </Button>
            </>
          )}
          {slug !== 'preview' && (
            <>
              <Button
                type="secondary"
                colored
                as="a"
                href="https://linktr.ee/srb_nft"
                target="_blank"
              >
                Buy a bear
              </Button>
              <Button
                type="secondary"
                as="a"
                href={`/bear/${story.bear.identifier}`}
                onClick={viewBear}
              >
                Look at the bear
              </Button>
            </>
          )}
        </Actions>
      </Grid>
    </StyledStoryModal>
  );
};

export default inject('storiesStore', 'uiStore')(observer(StoryModal));
