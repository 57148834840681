import { gsap } from 'gsap';
import { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import CursorImage from '@/assets/cursor.svg';
import { CursorGlobalStyle, CustomCursor } from '@/components/Cursor/styles';

const Cursor = () => {
  const cursorRef = useRef(null);
  const [loaded, setLoaded] = useState(false);

  const listener = (e) => {
    if (cursorRef.current == null) return;

    gsap.to(cursorRef.current, 0.2, {
      opacity: 1,
    });

    gsap.to(cursorRef.current, 0, {
      x: e.x - 4,
      y: e.y,
    });
  };

  useEffect(() => {
    setLoaded(true);
  }, []);

  useEffect(() => {
    if (isMobile) return;

    document.addEventListener('pointermove', listener);

    return () => {
      document.removeEventListener('pointermove', listener);
    };
  }, [loaded]);

  if (!loaded || isMobile) return null;

  return (
    <>
      <CursorGlobalStyle />
      <CustomCursor ref={cursorRef}>
        <CursorImage />
      </CustomCursor>
    </>
  );
};

export default Cursor;
