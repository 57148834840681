import styled, { css } from 'styled-components';
import { breakpoints } from '@/styles/breakpoints';

interface NavigationProps {
  small?: boolean;
}

const NavigationList = styled.ul<NavigationProps>`
  display: flex;
  flex-flow: row nowrap;
  list-style-type: none;
  padding: 0;
  margin: 0;
  line-height: 1;

  li {
    & + li {
      margin-left: ${({ theme }) => parseInt(theme.spacings.x2) + 4}px;

      ${breakpoints.MD} {
        margin-left: ${({ theme }) => theme.spacings.x3};
      }

      ${breakpoints.MDL} {
        margin-left: ${({ theme }) => theme.spacings.x6};
      }

      ${breakpoints.LG} {
        margin-left: ${({ theme }) => theme.spacings.x6};
      }
    }
  }

  ${({ theme, small }) =>
    small &&
    css`
      li {
        a {
          font-size: ${theme.fonts.sizes.mobile.small};
          line-height: ${theme.fonts.lineHeights.regular};
          padding: 0;
        }

        & + li {
          margin-left: ${parseInt(theme.spacings.x2) + 4}px;
        }
      }
    `}
`;

export { NavigationList };
