import styled, { createGlobalStyle } from 'styled-components';

const CursorGlobalStyle = createGlobalStyle`
  * {
    cursor: none;
  }
`;

const CustomCursor = styled.div`
  pointer-events: none;

  transform: translate(50vw, 50vh);

  position: fixed;
  z-index: 1001;
`;

export { CustomCursor, CursorGlobalStyle };
