import {
  Actions,
  BearModalContent,
  BearModalHeader,
  BearModalImage,
  BearModalTitle,
  BearModalToken,
  BearModalWrapper,
  MoreTraits,
  RarityScore,
  StyledBearModal,
  Trait,
  TraitLabel,
  Traits,
  TraitsList,
  TraitsTitle,
  TraitValue,
} from './styles';
import { IBear, IBearsStore } from '@/stores/Bears';
import { FC, useState } from 'react';
import { inject, observer } from 'mobx-react';
import Image from 'next/image';
import Badge from '@/components/Badge';
import Button from '@/components/UI/Button';
import DiamondSVG from '@/assets/diamond.svg';
import { IUIStore } from '@/stores/UI';

interface BearModalProps {
  bearsStore: IBearsStore;
  identifier: string;
  uiStore: IUIStore;
}

const BearModal: FC<any> = ({
  bearsStore,
  identifier,
  uiStore: { openModal },
}: BearModalProps) => {
  const bear: IBear = bearsStore.items.get(identifier);

  const [traitsOpen, setTraitsOpen] = useState(bear?.traits.length < 6);

  if (!bear) return null;

  const navigateStory = async (e) => {
    e.preventDefault();

    await openModal({ type: 'story', instance: bear.story.slug });

    return window.history.pushState(
      { scroll: false },
      '',
      `/stories/${bear.story.slug}`,
    );
  };

  return (
    <StyledBearModal>
      <BearModalWrapper>
        <BearModalImage>
          <div className="wrapper">
            <Image
              src={bear.image}
              layout="fill"
              alt={
                bear.story ? bear.story.name : `Rank ${bear.rank.toString()}`
              }
            />
          </div>
        </BearModalImage>
        <BearModalHeader>
          <BearModalTitle>
            {bear.story ? bear.story.name : `Rank ${bear.rank.toString()}`}
          </BearModalTitle>
          <BearModalToken>
            <Badge secondary>#{bear.tokenId}</Badge>
          </BearModalToken>
          <RarityScore>
            <span>Rarity Score</span>
            <Badge icon={<DiamondSVG viewBox="0 0 11 11" />}>
              {bear.rarityScore}
            </Badge>
          </RarityScore>
        </BearModalHeader>
        <BearModalContent>
          <Traits>
            <TraitsTitle>Traits</TraitsTitle>
            <TraitsList open={traitsOpen}>
              {bear.traits.map((trait) => (
                <Trait key={trait.type}>
                  <TraitLabel>{trait.type}</TraitLabel>
                  <TraitValue>{trait.value}</TraitValue>
                </Trait>
              ))}
            </TraitsList>
            {!traitsOpen && (
              <MoreTraits onClick={() => setTraitsOpen(true)}>
                More Traits
                <svg
                  viewBox="0 0 8 5"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M3.64645 4.85355C3.84171 5.04882 4.15829 5.04882 4.35355 4.85355L7.53553 1.67157C7.7308 1.47631 7.7308 1.15973 7.53553 0.964466C7.34027 0.769204 7.02369 0.769204 6.82843 0.964466L4 3.79289L1.17157 0.964466C0.976311 0.769204 0.659728 0.769204 0.464466 0.964466C0.269204 1.15973 0.269204 1.47631 0.464466 1.67157L3.64645 4.85355ZM3.5 3.5L3.5 4.5L4.5 4.5L4.5 3.5L3.5 3.5Z" />
                </svg>
              </MoreTraits>
            )}
          </Traits>
        </BearModalContent>
        <Actions>
          <Button
            colored
            as="a"
            href="https://linktr.ee/srb_nft"
            target="_blank"
          >
            Buy a bear
          </Button>
          {bear.story && (
            <Button
              as="a"
              href={`/stories/${bear.story.slug}`}
              onClick={navigateStory}
            >
              Story
            </Button>
          )}
        </Actions>
      </BearModalWrapper>
    </StyledBearModal>
  );
};

export default inject('bearsStore', 'uiStore')(observer(BearModal));
