import styled, { css } from 'styled-components';
import { breakpoints } from '@/styles/breakpoints';
import { StyledDropdown } from '@/components/Header/HeaderNavigation/styles';

const StyledHeader = styled.div<{ menuOpened: boolean; fixed: boolean }>`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  background: linear-gradient(180deg, #090909 0%, rgba(9, 9, 9, 0) 245%);
  backdrop-filter: blur(10px);
  transition: transform 0.2s ease-in-out;

  ${({ menuOpened, theme }) =>
    menuOpened &&
    css`
      background: ${theme.colors.bg};
    `}

  .wrapper {
    display: flex;
    align-content: center;
    align-items: center;
    flex-flow: row wrap;
    justify-content: space-between;
    padding: ${({ theme }) => theme.spacings.x2} 0;

    .header-action {
      margin-left: auto;
    }

    ${breakpoints.MD} {
      justify-content: space-between;

      .header-action {
        margin-left: unset;
      }
    }
  }

  & + * {
    padding-top: 86px;
  }

  ${({ fixed }) =>
    !fixed &&
    css`
      transform: translateY(-100%);
    `}
`;

const RightItems = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;

  ${breakpoints.MD} {
    margin-left: unset;
  }

  ${StyledDropdown} {
    display: none !important;

    ${breakpoints.LG} {
      display: flex !important;
    }
  }

  > a {
    margin-left: 0;

    ${breakpoints.LG} {
      margin-left: 60px !important;
    }
  }
`;

export { StyledHeader, RightItems };
