import styled from 'styled-components';
import { breakpoints } from '@/styles/breakpoints';

const SVGLogo = styled.svg.attrs({
  xmlns: 'http://www.w3.org/2000/svg',
})`
  width: 72px !important;
  height: 49px !important;

  ${breakpoints.MD} {
    width: 86px !important;
  }
`;

export { SVGLogo };
