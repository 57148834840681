const breakpointsBase = {
  XS: `(max-width: 575px)`,
  SM: `(min-width: 576px)`,
  MD: `(min-width: 768px)`,
  MDL: `(min-width: 992px) and (max-width: 1199px) and (orientation: landscape)`,
  LG: `(min-width: 1200px)`,
  XLG: `(min-width: 1441px)`,
  XXLG: `(min-width: 1920px)`,
};

const breakpoints = {
  SM: `@media screen and ${breakpointsBase.SM}`,
  MD: `@media screen and ${breakpointsBase.MD}`,
  MDL: `@media screen and ${breakpointsBase.MDL}`,
  LG: `@media screen and ${breakpointsBase.LG}`,
  XLG: `@media screen and ${breakpointsBase.XLG}`,
  XXLG: `@media screen and ${breakpointsBase.XXLG}`,
};

export { breakpoints, breakpointsBase };
