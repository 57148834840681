import { applySnapshot, flow, Instance, types } from 'mobx-state-tree';

export const UIStoreModel = types
  .model('UIStoreModel', {
    modalOpen: types.boolean,
    modalType: types.string,
    modalInstance: types.string,
    preloaded: types.boolean,
  })
  .actions((self) => {
    const openModal = flow(function* openModal({
      type,
      instance,
    }: {
      type: string;
      instance: string;
    }) {
      self.modalType = type;
      self.modalInstance = instance;
      self.modalOpen = true;
    });

    const closeModal = flow(function* closeModal() {
      self.modalType = '';
      self.modalInstance = '';
      self.modalOpen = false;
    });

    const setPreloaded = flow(function* setPreloaded(value: boolean) {
      self.preloaded = value;
    });

    return { openModal, closeModal, setPreloaded };
  });

export type IUIStore = Instance<typeof UIStoreModel>;

let uiStore: IUIStore;

export const initializeUIStore = (snapshot: IUIStore | null = null) => {
  const _uiStore =
    uiStore ??
    UIStoreModel.create({
      preloaded: false,
      modalOpen: false,
      modalType: '',
      modalInstance: '',
    });

  if (snapshot) {
    applySnapshot(_uiStore, snapshot);
  }

  if (typeof window === 'undefined') return _uiStore;

  if (!uiStore) uiStore = _uiStore;

  return uiStore;
};
