import styled, { css } from 'styled-components';
import { breakpoints } from '@/styles/breakpoints';

interface IOwnProps {
  type: 'primary' | 'secondary';
  colored?: boolean;
}

const StyledButton = styled.button<IOwnProps>`
  display: block;
  position: relative;

  &[disabled] {
    pointer-events: none;

    .text {
      opacity: 0.3;
    }
  }

  ${({ theme, type }) =>
    type === 'primary' &&
    css`
      text-align: center;
      letter-spacing: 0.2px;
      font-weight: ${theme.fonts.weights.bold};
      border-radius: 50%;
      font-size: ${theme.fonts.sizes.mobile.medium};
      line-height: ${theme.spacings.x16};
      width: ${theme.spacings.x16};
      height: ${theme.spacings.x16};
      color: ${theme.colors.text.dark};
      z-index: 2;

      ${breakpoints.LG} {
        font-size: ${theme.fonts.sizes.desktop.medium};
        width: ${theme.spacings.x19};
        height: ${theme.spacings.x19};
        line-height: ${theme.spacings.x19};
      }

      .wrap {
        display: block;
        border-radius: 50%;
        background-color: ${theme.colors.primary.second};
        position: relative;
        z-index: 2;
        width: ${theme.spacings.x16};
        height: ${theme.spacings.x16};

        ${breakpoints.LG} {
          width: ${theme.spacings.x19};
          height: ${theme.spacings.x19};
        }
      }

      .text {
        position: relative;
        z-index: 2;
        user-select: none;
      }

      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: ${theme.spacings.x16};
        height: ${theme.spacings.x16};
        border-radius: 50%;
        z-index: 1;
        transition: transform 0.2s ease-in-out;

        ${breakpoints.LG} {
          width: ${theme.spacings.x19};
          height: ${theme.spacings.x19};
        }
      }

      &:before {
        background-color: ${theme.colors.primary.first};
      }

      &:after {
        background-color: ${theme.colors.secondary.first};
      }

      &[disabled] {
        .text {
          opacity: 0.3;
        }
      }

      &:hover:enabled,
      &:not([disabled]):hover {
        color: ${theme.colors.text.dark};

        &:before {
          transform: translate(
            -${parseInt(theme.spacings.base) / (2 * 1.19)}px,
            -${parseInt(theme.spacings.base) / (2 * 1.19)}px
          );

          ${breakpoints.LG} {
            transform: translate(
              -${parseInt(theme.spacings.base) / 2}px,
              -${parseInt(theme.spacings.base) / 2}px
            );
          }
        }

        &:after {
          transform: translate(
            ${parseInt(theme.spacings.base) / (1.5 * 1.19)}px,
            ${parseInt(theme.spacings.base) / (1.5 * 1.19)}px
          );

          ${breakpoints.LG} {
            transform: translate(
              ${parseInt(theme.spacings.base) / 1.5}px,
              ${parseInt(theme.spacings.base) / 1.5}px
            );
          }
        }
      }

      &:active:enabled,
      &:not([disabled]):active {
        &:before {
          transform: translate(0, 0);
        }

        &:after {
          transform: translate(0, 0);
        }
      }
    `}

  ${({ theme, type }) =>
    type === 'secondary' &&
    css`
      color: ${theme.colors.text.white};
      font-weight: ${theme.fonts.weights.regular};
      font-size: ${theme.fonts.sizes.mobile.small};
      line-height: ${theme.fonts.lineHeights.xxLarge};
      border: ${parseInt(theme.spacings.base) / 4}px solid
        ${theme.colors.text.white};
      padding: ${parseInt(theme.spacings.base) - 4}px ${theme.spacings.x2};
      border-radius: ${parseInt(theme.spacings.x2) +
      parseInt(theme.spacings.base) / 2}px;
      letter-spacing: 0.002em;
      transition: 0.15s ease-in-out;
      transition-property: background-color, color;

      &[disabled] {
        border: 2px solid #a9a9a9;
      }

      &:hover:enabled,
      &:not([disabled]):hover {
        color: ${theme.colors.text.dark};
        background-color: ${theme.colors.text.white};
      }

      &:active:enabled,
      &:not([disabled]):active {
        color: ${theme.colors.text.white};
        background-color: transparent;
      }
    `}

    ${({ theme, type, colored }) =>
    type === 'secondary' &&
    colored &&
    css`
      border-color: ${theme.colors.primary.second};

      &:hover:enabled,
      &:not([disabled]):hover {
        background-color: ${theme.colors.primary.second};
      }

      &:active:enabled,
      &:not([disabled]):active {
        background-color: transparent;
      }
    `}
`;

export default StyledButton;
