import { StyledEGLD } from '@/components/EGLD/styles';

const EGLD = () => {
  return (
    <StyledEGLD>
      <div className="container">
        <iframe
          title="EGLD"
          src="https://egld.community/api/products/cd5442cf-0c0a-435e-8fd8-f72b3cc7a4e1/upvotes/embed"
          width="290"
          height="70"
          style={{ borderRadius: 8, border: 'none', width: 290, height: 70 }}
        />
      <script async src="https://widgets.coingecko.com/coingecko-coin-ticker-widget.js"></script>
      <coingecko-coin-ticker-widget  coin-id="superrarebears-rare" currency="usd" locale="en"></coingecko-coin-ticker-widget>
      <coingecko-coin-ticker-widget currency="usd" coin-id="superrarebears-hype" locale="en"></coingecko-coin-ticker-widget>
      </div>
    </StyledEGLD>
  );
};

export default EGLD;
