import {
  applySnapshot,
  flow,
  getEnv,
  IAnyStateTreeNode,
  Instance,
  types,
} from 'mobx-state-tree';
import { useMemo } from 'react';
import StrapiService from '@/services/StrapiService';
import { UIStoreModel, initializeUIStore } from './UI';
import { BearsStoreModel, initializeBearsStore } from '@/stores/Bears';
import { GalleryStoreModel, initializeGalleryStore } from '@/stores/Gallery';
import { initializeStoriesStore, StoriesStoreModel } from '@/stores/Stories';
import cloneDeep from 'lodash.clonedeep';

export const RootStoreModel: IAnyStateTreeNode = types
  .model('RootStore', {
    uiStore: types.maybe(UIStoreModel),
    bearsStore: types.maybe(BearsStoreModel),
    galleryStore: types.maybe(GalleryStoreModel),
    storiesStore: types.maybe(StoriesStoreModel),
  })
  .actions((self) => ({
    afterCreate: flow(function* load() {
      const stories = yield getEnv(self).strapiService.getAllStories();

      stories.forEach((story) => {
        const _story = cloneDeep(story);
        _story.bear.story = {
          id: story.id,
          name: story.bearName,
          slug: story.slug,
        };
        self.storiesStore.setItems([_story]);
        self.bearsStore.setItems([_story.bear]);
      });
    }),
  }));

export type IRootStore = Instance<typeof RootStoreModel>;

let rootStore: IRootStore;

export const initializeRootStore = (snapshot: IRootStore = null) => {
  const uiStore = initializeUIStore(snapshot?.authStore);
  const bearsStore = initializeBearsStore(snapshot?.bearsStore);
  const galleryStore = initializeGalleryStore(snapshot?.galleryStore);
  const storiesStore = initializeStoriesStore(snapshot?.storiesStore);

  const _rootStore =
    rootStore ??
    RootStoreModel.create(
      {
        uiStore,
        bearsStore,
        galleryStore,
        storiesStore,
      },
      {
        strapiService: new StrapiService(),
      },
    );

  if (rootStore) return rootStore;

  if (snapshot) {
    applySnapshot(_rootStore, snapshot);
  }

  if (typeof window === 'undefined') return _rootStore;

  if (!rootStore) rootStore = _rootStore;

  return rootStore;
};

export const useStores = (initialState?: IRootStore) => {
  return useMemo(() => initializeRootStore(initialState), [initialState]);
};
