import styled, { css } from 'styled-components';
import { breakpoints } from '@/styles/breakpoints';

interface LinkProps {
  active?: boolean;
}

const StyledLink = styled.a<LinkProps>`
  transition-property: color, font-weight;

  ${({ theme, active }) =>
    active &&
    css`
      color: ${theme.colors.primary.first};
      font-weight: ${theme.fonts.weights.bold};

      &:hover {
        color: ${theme.colors.primary.first};
      }
    `}

  ${breakpoints.MD} {
    padding: 0 ${({ theme }) => parseInt(theme.spacings.base) / 2}px;
  }

  ${breakpoints.MDL} {
    padding: 0 ${({ theme }) => parseInt(theme.spacings.base) - 2}px;
  }

  ${breakpoints.LG} {
    padding: 0 ${({ theme }) => parseInt(theme.spacings.base) - 2}px;
  }
`;

export { StyledLink };
