import {
  Actions,
  CreateStoryModal,
  Form,
  Title,
} from '@/scenes/Stories/CreateModal/styles';
import Button from '@/components/UI/Button';
import Input from '@/components/UI/Input';
import { inject, observer } from 'mobx-react';
import { IStoriesStore } from '@/stores/Stories';
import { FC } from 'react';
import { IUIStore } from '@/stores/UI';

interface CreateModalProps {
  storiesStore: IStoriesStore;
  uiStore: IUIStore;
}

const CreateModal: FC<any> = ({
  storiesStore: { preview, updatePreview, previewValid, createStory },
  uiStore: { openModal },
}: CreateModalProps) => {
  const handleChange = async (e) => {
    await updatePreview(e.currentTarget.name, e.currentTarget.value);
  };

  const openPreview = () => {
    openModal({ type: 'story', instance: 'preview' });
  };

  const onPost = () => {
    createStory();
  };

  return (
    <CreateStoryModal>
      <div>
        <Title>Story creation</Title>
        <Form>
          <Input
            placeholder="Token ID (1-10000)"
            name="token"
            label="SRB"
            type="text"
            value={preview.bear.tokenId ? preview.bear.tokenId.toString() : ''}
            onChange={handleChange}
            error={
              preview.bear.story?.id ? 'This bear already has a story' : ''
            }
          />
          <Input
            placeholder="Enter the story name"
            name="title"
            label="Story name"
            type="text"
            value={preview.title}
            onChange={handleChange}
          />
          <Input
            placeholder="Name"
            name="bearName"
            label="Bear name"
            type="text"
            value={preview.bearName.toString()}
            onChange={handleChange}
          />
          <Input
            placeholder="Age"
            name="bearAge"
            label="Bear age"
            type="text"
            value={preview.bearAge ? preview.bearAge.toString() : ''}
            onChange={handleChange}
          />
          <Input
            placeholder="Write a story"
            name="story"
            label="Story text"
            type="textarea"
            value={preview.story}
            onChange={handleChange}
          />
        </Form>
        <Actions>
          <Button
            type="secondary"
            colored
            disabled={!previewValid}
            onClick={onPost}
          >
            Post the story
          </Button>
          <Button
            onClick={openPreview}
            type="secondary"
            disabled={!previewValid}
          >
            Preview
          </Button>
        </Actions>
      </div>
    </CreateStoryModal>
  );
};

export default inject('storiesStore', 'uiStore')(observer(CreateModal));
