import Logo from '@/components/Logo';
import HeaderNavigation from '@/components/Header/HeaderNavigation';
import { RightItems, StyledHeader } from '@/components/Header/styles';
import Button from '@/components/UI/Button';
import Link from 'next/link';
import MobileNavigation from '@/components/MobileNavigation';
import { useCallback, useEffect, useState } from 'react';
import LinksDropdown from '@/components/Header/HeaderNavigation/LinksDropdown';

const Header = () => {
  const [menuOpened, setMenuOpened] = useState(false);
  const [direction, setDirection] = useState<string>('up');
  const [loaded, setLoaded] = useState(false);

  const [y, setY] = useState(0);

  const handleScroll = useCallback(
    (e) => {
      const window = e.currentTarget;

      if (window.scrollY <= 0) {
        setDirection('up');
      } else {
        if (y > window.scrollY) {
          setDirection('up');
        } else {
          setDirection('down');
        }
      }

      setY(window.scrollY);
    },
    [y],
  );

  useEffect(() => {
    setLoaded(true);
  }, []);

  useEffect(() => {
    if (!loaded) return;
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [loaded, handleScroll]);

  return (
    <StyledHeader menuOpened={menuOpened} fixed={direction === 'up'}>
      <div className="container">
        <div className="wrapper">
          <Link href="/" prefetch={false}>
            <a aria-label="Super Rare Bears">
              <Logo />
            </a>
          </Link>
          <HeaderNavigation />
          <RightItems>
            <LinksDropdown />
            <Button
              as="a"
              href="https://linktr.ee/srb_nft"
              target="_blank"
              type="secondary"
              colored
              className="header-action"
            >
              Buy a bear
            </Button>
          </RightItems>
          <MobileNavigation onToggle={(opened) => setMenuOpened(opened)} />
        </div>
      </div>
    </StyledHeader>
  );
};

export default Header;
