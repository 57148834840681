import styled from 'styled-components';
import { breakpoints } from '@/styles/breakpoints';
import { Error } from '@/components/UI/Input/styles';

const CreateStoryModal = styled.div`
  padding: ${({ theme }) => theme.spacings.x2}
    ${({ theme }) => theme.spacings.x2} ${({ theme }) => theme.spacings.x4};
  height: 100%;
  overflow-y: auto;

  ${breakpoints.MD} {
    padding: ${({ theme }) => theme.spacings.x2}
      ${({ theme }) => theme.spacings.x7} ${({ theme }) => theme.spacings.x4};
  }

  ${breakpoints.LG} {
    max-height: 90vh;
  }

  & > div {
    ${breakpoints.LG} {
      min-height: 716px;
    }
  }
`;

const Title = styled.h3`
  padding-right: 20%;
  color: ${({ theme }) => theme.colors.primary.first};

  ${breakpoints.MD} {
    padding-right: 0;
  }
`;

const Form = styled.div`
  display: grid;
  grid-template-areas:
    'token token'
    'name name'
    'bearName age'
    'text text';
  column-gap: ${({ theme }) => theme.spacings.x2};
  row-gap: ${({ theme }) => theme.spacings.x2};
  grid-template-columns: 1fr 72px;
  margin: ${({ theme }) => theme.spacings.x4} 0;

  ${breakpoints.MD} {
    column-gap: ${({ theme }) => theme.spacings.x4};
  }

  & > div:first-child {
    grid-area: token;
  }

  & > div:nth-child(2) {
    grid-area: name;
  }

  & > div:nth-child(3) {
    grid-area: bearName;
  }

  & > div:nth-child(4) {
    grid-area: age;
  }

  & > div:nth-child(5) {
    grid-area: text;
  }

  ${Error} {
    left: unset;
    right: 0;
    top: -20px;
  }
`;

const Actions = styled.div`
  display: flex;
  align-items: center;

  & > * {
    & + * {
      margin-left: ${({ theme }) => theme.spacings.x4};
    }
  }
`;

export { CreateStoryModal, Title, Form, Actions };
