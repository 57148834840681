import {
  PreloaderGlobalStyles,
  PreloaderLogo,
  StyledPreloader,
  Title,
} from '@/scenes/common/Preloader/styles';
import { inject, observer } from 'mobx-react';
import { IUIStore } from '@/stores/UI';
import Logo from '@/components/Logo';
import { FC, useEffect, useRef, useState } from 'react';
import { gsap, Power3 } from 'gsap';
import useMediaQuery from '@/hooks/useMediaQuery';
import { breakpointsBase } from '@/styles/breakpoints';

interface PreloaderProps {
  uiStore: IUIStore;
}

const Preloader: FC<any> = ({
  uiStore: { preloaded, setPreloaded },
}: PreloaderProps) => {
  const [loaded, setLoaded] = useState(false);

  const textRef = useRef(null);
  const logoRef = useRef(null);
  const wrapperRef = useRef(null);

  const isMD = useMediaQuery(breakpointsBase.MD);
  const isMDL = useMediaQuery(breakpointsBase.MDL);
  const isLG = useMediaQuery(breakpointsBase.LG);
  const isXXLG = useMediaQuery(breakpointsBase.XXLG);

  useEffect(() => {
    setLoaded(true);

    document.body.dataset.preloaded = 'false';
  }, []);

  useEffect(() => {
    if (!loaded) return;

    const tl = gsap.timeline();

    let containerPadding = 18;
    let middleAnimationPosition = 'translate(-50%, -55vw) scaleY(1.7)';
    let topAnimationPosition = 'translate(-50%, -110vw) scaleY(0.01)';

    if (isMD) {
      containerPadding = 40;
      topAnimationPosition = 'translate(-50%, -105vw) scaleY(0.01)';
    }

    if (isMDL) {
      containerPadding = 62;
      middleAnimationPosition = 'translate(-50%, -25vw) scaleY(1.7)';
      topAnimationPosition = 'translate(-50%, -50vw) scaleY(0.01)';
    }

    if (isLG) {
      containerPadding = 62;
      middleAnimationPosition = 'translate(-50%, -35vh) scaleY(1.7)';
      topAnimationPosition = 'translate(-50%, -80vh) scaleY(0.01)';
    }

    if (isXXLG) {
      containerPadding = 120;
      middleAnimationPosition = 'translate(-50%, -35vh) scaleY(1.7)';
      topAnimationPosition = 'translate(-50%, -85vh) scaleY(0.01)';
    }

    tl.to(textRef.current, {
      transform: middleAnimationPosition,
      duration: 1,
      ease: Power3.easeIn,
    })
      .to(textRef.current, {
        delay: 1,
        transform: topAnimationPosition,
        duration: 2,
        ease: Power3.easeOut,
      })
      .to(
        textRef.current,
        {
          opacity: 0,
          duration: 0.2,
        },
        3.5,
      )
      .to(
        logoRef.current,
        {
          ease: Power3.easeIn,
          duration: 1,
          left: containerPadding,
          transform: 'translate(0)',
          onComplete: () => {
            setTimeout(() => {
              document.body.dataset.preloaded = 'true';
              setPreloaded(true);
            }, 100);
          },
        },
        3,
      )
      .to(wrapperRef.current, {
        opacity: 0,
        duration: 0.3,
      });

    return () => {
      tl.kill();
    };
  }, [loaded]);

  if (preloaded) return null;

  return (
    <>
      <PreloaderGlobalStyles />
      <StyledPreloader ref={wrapperRef}>
        <PreloaderLogo ref={logoRef}>
          <Logo />
        </PreloaderLogo>
        <Title ref={textRef}>Welcome to</Title>
      </StyledPreloader>
    </>
  );
};

export default inject('uiStore')(observer(Preloader));
