import {
  DropdownHeader,
  DropdownLink,
  DropdownMenu,
  StyledDropdown,
} from '@/components/Header/HeaderNavigation/styles';
import { useState } from 'react';

const links = [
  {
    label: 'Stake your Bears',
    url: 'https://xoxno.com/staking',
  },
  {
    label: 'RAREnomics',
    url: 'https://medium.com/@superrarebears/srb-rarenomics-esdt-token-rare-distribution-v3-a2cf8b0e7090',
  },
  {
    label: 'Buy $RARE',
    url: 'https://jungledex.com/swap?firstToken=USDC-c76f1f&secondToken=RARE-99e8b0',
  },
  {
    label: 'BUY $HYPE',
    url: 'https://jungledex.com/swap?firstToken=USDC-c76f1f&secondToken=HYPE-619661',
  },
  {
    label: 'Hypenomics',
    url: 'https://medium.com/@superrarebears/hypenomics-v1-c3d880828f48',
  },
  {
    label: 'Farms',
    url: 'https://www.quantumx.network/farms',
  },
  {
    label: 'Pools',
    url: 'https://www.quantumx.network/pools',
  },
  {
    label: 'Hypezone',
    url: 'https://www.quantumx.network/hypezone',
  },
];

const LinksDropdown = () => {
  const [open, setOpen] = useState(false);

  return (
    <StyledDropdown open={open}>
      <DropdownHeader open={open} onClick={() => setOpen(!open)}>
        <span>BEAR PATH</span>
        <svg
          width="18"
          height="9"
          viewBox="0 0 18 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.3072 0.109689L0.821918 8.59497H17.7925L9.3072 0.109689Z"
            fill="white"
          />
        </svg>
      </DropdownHeader>
      <DropdownMenu>
        {links.map(({ label, url }) => (
          <DropdownLink key={url} href={url} target="_blank">
            <span>{label}</span>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_3922_7859)">
                <path
                  d="M7 2.604H3C1.89543 2.604 1 3.49943 1 4.604V12.604C1 13.7086 1.89543 14.604 3 14.604H11C12.1046 14.604 13 13.7086 13 12.604V8.604"
                  stroke="white"
                  strokeLinecap="round"
                />
                <line
                  x1="6"
                  y1="9.8969"
                  x2="14.2929"
                  y2="1.604"
                  stroke="white"
                  strokeLinecap="round"
                />
                <path
                  d="M11 1L14.9999 1.02932L14.9706 5.02921"
                  stroke="white"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_3922_7859">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </DropdownLink>
        ))}
      </DropdownMenu>
    </StyledDropdown>
  );
};

export default LinksDropdown;
