import {
  Burger,
  Overlay,
  StyledMobileNavigation,
  Wrapper,
} from '@/components/MobileNavigation/styles';
import Navigation from '@/components/UI/Navigation';
import { useEffect, useState } from 'react';
import LinksDropdown from '@/components/Header/HeaderNavigation/LinksDropdown';
import { mainNav } from '@/components/Header/HeaderNavigation/HeaderNavigation';

interface MobileNavigationProps {
  onToggle: (opened: boolean) => void;
}

const MobileNavigation = ({ onToggle }: MobileNavigationProps) => {
  const [opened, setOpened] = useState(false);

  const toggleMenu = () => {
    setOpened(!opened);
    onToggle(!opened);
  };

  const handleClick = (e) => {
    if (e.target.tagName === 'A') {
      setOpened(false);
      onToggle(false);
    }
  };

  useEffect(() => {
    if (opened) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }
  }, [opened]);

  return (
    <StyledMobileNavigation>
      <Burger aria-label="Open menu" onClick={toggleMenu} opened={opened} />
      <Overlay opened={opened} />
      <Wrapper opened={opened} onClick={handleClick}>
        <Navigation items={mainNav} />
        <LinksDropdown />
      </Wrapper>
    </StyledMobileNavigation>
  );
};

export default MobileNavigation;
