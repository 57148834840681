import {
  applySnapshot,
  cast,
  flow,
  getEnv,
  getParent,
  Instance,
  types,
} from 'mobx-state-tree';
import { BearModel } from '@/stores/Bears';
import { IRootStore } from '@/stores/index';
import { values } from 'mobx';
import cloneDeep from 'lodash.clonedeep';

function slugify(text) {
  const from = 'ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;';
  const to = 'aaaaaeeeeeiiiiooooouuuunc------';

  const newText = text
    .split('')
    .map((letter, i) =>
      letter.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i)),
    );

  return newText
    .toString() // Cast to string
    .toLowerCase() // Convert the string to lowercase letters
    .trim() // Remove whitespace from both sides of a string
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/&/g, '-y-') // Replace & with 'and'
    .replace(/[^\w\-]+/g, '') // Remove all non-word chars
    .replace(/\-\-+/g, '-'); // Replace multiple - with single -
}

export const StoryModel = types.model('Story', {
  id: types.maybeNull(types.number),
  slug: types.string,
  title: types.string,
  story: types.string,
  bearName: types.string,
  bearAge: types.maybeNull(types.number),
  bear: BearModel,
});

export type IStory = Instance<typeof StoryModel>;

export const StoriesStoreModel = types
  .model('StoriesStore', {
    visibleItems: types.array(StoryModel),
    items: types.map(StoryModel),
    total: types.number,
    loading: types.boolean,
    preview: StoryModel,
  })
  .actions((self) => {
    const setItems = flow(function* setItems(stories: IStory[]) {
      stories.forEach((item) => {
        self.items.set(item.slug, item);
      });
    });

    const loadItems = flow(function* loadItems() {
      self.loading = true;

      const { items, total } = yield getEnv(self).strapiService.getStories(
        self.visibleItems.length,
      );

      // @ts-ignore
      yield self.setItems(items);
      self.visibleItems.push(...items);

      self.total = total;
      self.loading = false;
    });

    const setVisibleItems = flow(function* setVisibleItems(stories: IStory[]) {
      self.visibleItems = cast(stories);
    });

    const updatePreview = flow(function* updatePreview(field, value) {
      if (field === 'token') {
        self.preview.bear.tokenId = parseInt(value);
        const existingBear = values(
          getParent<IRootStore>(self).bearsStore.items,
        ).filter((b) => b.tokenId === self.preview.bear.tokenId)[0];

        if (existingBear) {
          self.preview.bear = cloneDeep(existingBear);
        } else {
          if (value.length && parseInt(value) > 0 && parseInt(value) <= 10000) {
            const bear = yield getEnv(self).strapiService.getBearByToken(
              self.preview.bear.tokenId,
            );
            if (bear) {
              self.preview.bear = bear;
            } else {
              self.preview.bear.identifier = '';
            }
          } else {
            self.preview.bear.identifier = '';
          }
        }
      } else if (field === 'bearAge') {
        self.preview[field] = parseInt(value);
      } else {
        self.preview[field] = value;
      }
    });

    const createStory = flow(function* createStory() {
      const story = {
        ...self.preview,
        slug: slugify(self.preview.title),
        bear: self.preview.bear.id,
        publishedAt: null,
      };
      delete story.id;
      yield getEnv(self).strapiService.createStory(story);
      getParent<IRootStore>(self).uiStore.openModal({
        type: 'greeting',
        instance: '',
      });
      self.preview = StoryModel.create({
        id: null,
        slug: '',
        title: '',
        story: '',
        bearName: '',
        bearAge: null,
        bear: BearModel.create({
          id: null,
          tokenId: null,
          identifier: '',
          image: '',
          rank: 0,
          rarityScore: 0,
          featured: false,
        }),
      });
    });

    return { setItems, loadItems, updatePreview, createStory, setVisibleItems };
  })
  .views((self) => ({
    get previewValid() {
      return (
        !(
          !self.preview.bear.identifier ||
          !self.preview.title ||
          !self.preview.bearName ||
          !self.preview.bearAge ||
          !self.preview.story
        ) && !self.preview.bear.story?.id
      );
    },
  }));

export type IStoriesStore = Instance<typeof StoriesStoreModel>;

let storiesStore: IStoriesStore;

export const initializeStoriesStore = (snapshot = null) => {
  // if (storiesStore) return storiesStore;

  const _storiesStore =
    storiesStore ??
    StoriesStoreModel.create({
      loading: false,
      visibleItems: [],
      total: 0,
      preview: StoryModel.create({
        id: null,
        slug: '',
        title: '',
        story: '',
        bearName: '',
        bearAge: null,
        bear: BearModel.create({
          id: null,
          tokenId: null,
          identifier: '',
          image: '',
          rank: 0,
          rarityScore: 0,
          featured: false,
        }),
      }),
    });

  if (snapshot) {
    applySnapshot(_storiesStore, snapshot);
  }

  if (typeof window === 'undefined') return _storiesStore;

  if (!storiesStore) storiesStore = _storiesStore;

  return storiesStore;
};
