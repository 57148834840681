import styled from 'styled-components';
import { breakpoints } from '@/styles/breakpoints';

const StyledStoryModal = styled.div`
  max-height: 100%;
  height: 100%;
  position: relative;

  ${breakpoints.MD} {
    padding: 0 ${({ theme }) => theme.spacings.x2}
      ${({ theme }) => theme.spacings.x4} ${({ theme }) => theme.spacings.x4};
    max-height: 445px;
    height: 445px;
  }

  ${breakpoints.MDL} {
    max-height: 378px;
    height: 378px;
    padding: 0 ${({ theme }) => theme.spacings.x2}
      ${({ theme }) => theme.spacings.x2} ${({ theme }) => theme.spacings.x2};
  }

  ${breakpoints.LG} {
    max-height: 459px;
    height: 459px;
  }
`;

const Grid = styled.div`
  display: grid;
  row-gap: ${({ theme }) => theme.spacings.x2};
  padding-bottom: 94px;

  ${breakpoints.MD} {
    grid-template-areas:
      'title title'
      'rarityScore rarityScore'
      'image content'
      'image actions';
    row-gap: ${({ theme }) => theme.spacings.base};
    padding-bottom: 0;
  }

  ${breakpoints.MDL} {
    grid-template-areas:
      'image title'
      'image rarityScore'
      'image content'
      'image actions';
  }

  ${breakpoints.LG} {
    grid-template-areas:
      'image title'
      'image rarityScore'
      'image content'
      'image actions';
    grid-template-columns: 427px auto 1fr;
    align-items: start;
    align-content: start;
    place-content: start;
    place-items: start;
    grid-template-rows: 31px 32px 1fr 1fr;
  }
`;

const StoryImage = styled.div`
  margin: 0 auto;
  width: 311px;
  height: 311px;
  position: relative;

  @media (max-height: 650px) {
    width: 250px;
    height: 250px;
  }

  ${breakpoints.MD} {
    grid-area: image;
    width: 342px;
    height: 342px;
  }

  ${breakpoints.MDL} {
    width: 362px;
    height: 362px;
  }

  ${breakpoints.LG} {
    width: 427px;
    height: 427px;
  }
`;

const Title = styled.h4`
  padding: 0 ${({ theme }) => theme.spacings.x4};

  && {
    margin-bottom: 0;
  }

  ${breakpoints.MD} {
    padding: 0;
  }

  ${breakpoints.MDL} {
    padding-left: ${({ theme }) => theme.spacings.x2};
    white-space: nowrap;
  }

  ${breakpoints.LG} {
    padding-left: ${({ theme }) => theme.spacings.x4};
    white-space: nowrap;
  }
`;

const Token = styled.div`
  padding: 0 ${({ theme }) => theme.spacings.x4};
  justify-self: start;
  margin-top: 4px;

  ${breakpoints.MD} {
    margin-top: 0;
    padding: 0;
    align-self: center;
    margin-left: ${({ theme }) => theme.spacings.base};
  }

  ${breakpoints.LG} {
    margin-left: ${({ theme }) => theme.spacings.x2};
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;

  ${breakpoints.MD} {
    grid-area: title;
  }

  ${breakpoints.LG} {
    align-self: start;
  }

  ${Title} {
    width: 100%;

    ${breakpoints.MD} {
      width: auto;
    }
  }
`;

const RarityScore = styled.div`
  padding: 0 ${({ theme }) => theme.spacings.x4};
  display: flex;
  margin-bottom: ${({ theme }) => theme.spacings.x2};
  align-items: center;
  font-size: 12px;
  line-height: 1px;
  margin-top: -2px;

  & > span {
    margin-right: 8px;
  }

  & > *:last-child {
    height: 16px;

    ${breakpoints.LG} {
      padding-bottom: 1px;
      position: relative;
      top: 1px;
    }

    & > *:first-child {
      transform: translateY(-1px);
    }
  }

  ${breakpoints.MD} {
    padding: 0 0 8px;
    grid-area: rarityScore;
    margin: -5px 0 0;
  }

  ${breakpoints.MDL} {
    padding: 0 ${({ theme }) => theme.spacings.x2};
    margin-bottom: ${({ theme }) => theme.spacings.x2};
    margin-top: -4px;
  }

  ${breakpoints.LG} {
    padding: 0 ${({ theme }) => theme.spacings.x4};
    margin-bottom: ${({ theme }) => theme.spacings.x2};
    //margin-top: -8px;
    margin-top: 0;
  }
`;

const Content = styled.div`
  padding: 0 ${({ theme }) => theme.spacings.x2} 0
    ${({ theme }) => theme.spacings.x4};
  margin-right: ${({ theme }) => theme.spacings.x2};
  overflow-y: auto;

  @media (max-height: 650px) {
    height: 85px !important;
  }

  ${breakpoints.MD} {
    grid-area: content;
    height: 279px !important;
    margin-right: 0;
    padding: 0 ${({ theme }) => theme.spacings.x2};
  }

  ${breakpoints.MDL} {
    padding: 0 ${({ theme }) => theme.spacings.x2};
    height: 219px !important;
  }

  ${breakpoints.LG} {
    padding: 0 ${({ theme }) => theme.spacings.x4};
    height: 286px !important;
  }
`;

const Attributes = styled.div`
  display: grid;
  column-gap: ${({ theme }) => theme.spacings.x2};
  row-gap: ${({ theme }) => theme.spacings.x2};
  grid-template-areas:
    'name age'
    'story story';
  grid-template-columns: 1fr auto;

  ${breakpoints.MD} {
    grid-template-areas:
      'name age . .'
      'story story story .';
  }

  ${breakpoints.LG} {
    grid-template-columns: repeat(2, 2fr);
  }

  & > *:first-child {
    grid-area: name;
  }

  & > *:nth-child(2) {
    grid-area: age;
  }

  & > *:nth-child(3) {
    grid-area: story;
  }

  p {
    & + p {
      margin-top: ${({ theme }) => theme.spacings.x2};
    }
  }
`;

const AttributeLabel = styled.div`
  color: ${({ theme }) => theme.colors.text.grey};
  margin-bottom: ${({ theme }) => theme.spacings.base};
  font-size: 12px;
  line-height: 12px;
`;

const AttributeContent = styled.div`
  font-size: 16px;
  line-height: 22px;

  p {
    font-size: 16px;
    line-height: 22px;
  }
`;

const Actions = styled.div`
  padding: ${({ theme }) => theme.spacings.x4};
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${({ theme }) => theme.colors.bg};
  align-items: center;

  & > * {
    & + * {
      margin-left: ${({ theme }) => theme.spacings.x2};
    }
  }

  ${breakpoints.MD} {
    grid-area: actions;
    position: static;
    padding: ${({ theme }) => theme.spacings.x3} 0 0
      ${({ theme }) => theme.spacings.x2};
  }

  ${breakpoints.MDL} {
    padding: ${({ theme }) => theme.spacings.x3}
      ${({ theme }) => theme.spacings.x2} 0;
  }

  ${breakpoints.LG} {
    padding: ${({ theme }) => theme.spacings.x3}
      ${({ theme }) => theme.spacings.x4} 0;
  }
`;

export {
  StyledStoryModal,
  Grid,
  StoryImage,
  Title,
  Token,
  RarityScore,
  Content,
  Attributes,
  AttributeLabel,
  AttributeContent,
  Actions,
  TitleWrapper,
};
