import Link from 'next/link';
import { FC } from 'react';
import { StyledLink } from '@/components/UI/Navigation/Item/styles';

export interface INavigationItem {
  label: string;
  url: string;
  external?: boolean;
  className?: string;
  active?: boolean;
}

const NavigationItem: FC<INavigationItem> = ({
  label,
  url,
  external,
  className = '',
  active,
}) => {
  return (
    <Link href={url} prefetch={false}>
      <StyledLink
        className={className}
        active={active}
        href={url}
        {...(external ? { target: 'blank' } : {})}
      >
        {label}
      </StyledLink>
    </Link>
  );
};

export default NavigationItem;
