import { FC, HTMLProps, ReactElement, useState } from 'react';
import { Label, StyledInput, Wrapper, Error, Action } from './styles';
import Button from '@/components/UI/Button';

interface InputProps {
  label?: string;
  error?: string;
  rounded?: boolean;
  small?: boolean;
  customAction?: InputAction;
  icon?: ReactElement;
  value: string;
  onChange: (e) => void;
  onBlur?: (e) => void;
}

interface InputAction {
  label: string;
  onAction: () => void;
}

const Input: FC<InputProps & HTMLProps<HTMLInputElement>> = ({
  label,
  placeholder,
  type,
  disabled,
  error,
  rounded,
  customAction,
  small,
  icon,
  value,
  onChange,
  onBlur,
  name,
}) => {
  const [focused, setFocused] = useState<boolean>(false);

  const onKeyDown = (e) => {
    if (customAction && e.code === 'Enter') {
      customAction.onAction();
    }
  };

  const handleBlur = (e) => {
    setFocused(false);
    if (onBlur) {
      onBlur(e);
    }
  };

  return (
    <Wrapper>
      {label && <Label className="text-small">{label}</Label>}
      <StyledInput
        error={error}
        rounded={rounded}
        small={small}
        focused={focused}
      >
        {icon && icon}
        {type !== 'textarea' && (
          <input
            type={type}
            placeholder={placeholder}
            disabled={disabled}
            onFocus={() => setFocused(true)}
            onBlur={handleBlur}
            value={value}
            onChange={onChange}
            onKeyDown={onKeyDown}
            name={name}
          />
        )}
        {type === 'textarea' && (
          <textarea
            placeholder={placeholder}
            disabled={disabled}
            onFocus={() => setFocused(true)}
            onBlur={handleBlur}
            value={value}
            onChange={onChange}
            onKeyDown={onKeyDown}
            name={name}
          />
        )}
        {error && <Error className="text-small">{error}</Error>}
        {customAction && (
          <Action small={small}>
            <Button onClick={customAction.onAction} colored disabled={disabled}>
              {customAction.label}
            </Button>
          </Action>
        )}
      </StyledInput>
    </Wrapper>
  );
};

export default Input;
