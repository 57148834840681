import { gsap } from 'gsap';
import Image from 'next/image';
import {
  BearId,
  BearImage,
  BearInfo,
  BearLink,
  BearName,
  StyledBearCard,
} from '@/components/Bear/Card/styles';
import Badge from '@/components/Badge';
import { FC, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import { IUIStore } from '@/stores/UI';
import { IBear } from '@/stores/Bears';

interface CardProps {
  bear: IBear;
  uiStore: IUIStore;
}

const Card: FC<any> = ({ bear, uiStore }: CardProps) => {
  const cardRef = useRef(null);
  const linkRef = useRef(null);

  const [loaded, setLoaded] = useState(false);
  const [height, setHeight] = useState(162);
  const [secondaryColor, setSecondaryColor] = useState(false);

  const router = useRouter();
  let rel = '';

  if (router.asPath !== '/') {
    rel = '?rel=gallery';
  }

  const link = `/bear/${bear.identifier}${rel}`;

  const resize = () => {
    if (!cardRef.current) return;
    setHeight(cardRef.current.offsetWidth);
  };

  const handleNavigation = async (e) => {
    e.preventDefault();

    await uiStore.openModal({ type: 'bear', instance: bear.identifier });

    return window.history.pushState({ scroll: false }, '', link);
  };

  useEffect(() => {
    setLoaded(true);

    setSecondaryColor(Math.floor(Math.random() * 2 + 1) === 1);
  }, []);

  const onMouseMove = (e) => {
    const min = -10;
    const center = -5;

    const ratio = min - center;

    const rect = e.currentTarget.getBoundingClientRect();

    const centerY = rect.height / 2;
    const centerX = rect.width / 2;
    const y = e.clientY - rect.top;
    const x = e.clientX - rect.left;

    const moveY = -(ratio / centerY) * y - 10;
    const moveX = -(ratio / centerX) * x - 10;

    gsap.to(linkRef.current, 0.3, {
      y: moveY,
      x: moveX,
    });
  };

  const onMouseLeave = () => {
    gsap.to(linkRef.current, 0.3, {
      y: 0,
      x: 0,
    });
  };

  useEffect(() => {
    if (!loaded) return;

    resize();

    // required because of slick initialization time
    setTimeout(() => {
      resize();
    }, 10);

    window.addEventListener('resize', resize);

    return () => {
      window.removeEventListener('resize', resize);
    };
  }, [loaded]);

  return (
    <BearLink
      href={link}
      onClick={handleNavigation}
      ref={linkRef}
      onMouseMove={onMouseMove}
      onMouseLeave={onMouseLeave}
      secondary={secondaryColor}
    >
      <StyledBearCard ref={cardRef}>
        <BearImage style={{ height }}>
          <Image
            src={bear.image}
            placeholder="blur"
            blurDataURL="https://raw.githubusercontent.com/ElrondNetwork/assets/master/tokens/SRB-61daf7/logo.png"
            layout="fill"
            alt={bear.story ? bear.story.name : `Rank ${bear.rank.toString()}`}
            unoptimized
          />
        </BearImage>
        <BearInfo style={{ height: `calc(100% - ${height}px)` }}>
          <BearName>
            {bear.story ? bear.story.name : `Rank ${bear.rank.toString()}`}
          </BearName>
          <BearId>
            <Badge>#{bear.tokenId}</Badge>
          </BearId>
        </BearInfo>
      </StyledBearCard>
    </BearLink>
  );
};

export default inject('uiStore')(observer(Card));
