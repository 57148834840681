import { INavigationItem } from '@/components/UI/Navigation/Item/NavigationItem';
import Navigation from '@/components/UI/Navigation';
import { StyledHeaderNav } from '@/components/Header/HeaderNavigation/styles';
import { useRouter } from 'next/router';

export const mainNav: INavigationItem[] = [
  {
    label: 'Home',
    url: '/',
  },
  {
    label: 'Gallery',
    url: '/gallery',
  },
  {
    label: 'Bear Stories',
    url: '/stories',
  },
  {
    label: 'Samurai Pandamonium',
    url: 'https://game.superrarebears.com/',
    external: true,
  },
  {
    label: 'Grabber',
    url: 'https://grabber.superrarebears.com/',
    external: true,
  },
];

const HeaderNavigation = () => {
  const router = useRouter();

  return (
    <StyledHeaderNav>
      <Navigation items={mainNav} activeItem={router.route} />
      {/*<LinksDropdown />*/}
    </StyledHeaderNav>
  );
};

export default HeaderNavigation;
