import styled, { css } from 'styled-components';
import { breakpoints } from '@/styles/breakpoints';

interface LinkProps {
  secondary: boolean;
}

const BearLink = styled.a<LinkProps>`
  position: relative;
  display: block;
  height: 100%;

  &:after {
    content: '';
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    position: absolute;
    top: 4px;
    bottom: 0;
    left: 4px;
    right: 0;
    background-color: ${({ theme }) => theme.colors.primary.first};
    z-index: 2;
    transform: translate(-3px, -3px);
    transition: all 0.3s ease-in-out;

    ${({ secondary }) =>
      secondary &&
      css`
        background-color: ${({ theme }) => theme.colors.primary.second};
      `}
  }

  @media (hover: hover) {
    &:hover {
      color: #fff;

      &:after {
        transform: translate(6px, 6px);
      }
    }
  }
`;

const StyledBearCard = styled.article`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  position: relative;
  z-index: 3;
  background-color: ${({ theme }) => theme.colors.bg};
  height: 100%;

  & > * {
    width: 100%;
  }
`;

const BearImage = styled.div`
  height: 162px;
  position: relative;
`;

const BearInfo = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  border: 2px solid #fff;
  border-top: none;
  padding: ${({ theme }) =>
    `11px ${theme.spacings.base} ${theme.spacings.base} ${theme.spacings.base}`};

  ${breakpoints.MD} {
    padding: ${({ theme }) =>
      `${parseInt(theme.spacings.x2) + 2}px ${theme.spacings.base} ${
        theme.spacings.x2
      } ${theme.spacings.base}`};
  }

  ${breakpoints.MDL} {
    padding: ${({ theme }) =>
      `${parseInt(theme.spacings.x2)}px ${theme.spacings.x2}
      ${parseInt(theme.spacings.x2) - 2}px ${theme.spacings.x2}`};
  }

  ${breakpoints.LG} {
    padding: ${({ theme }) =>
      `${parseInt(theme.spacings.x2) + 3}px ${theme.spacings.x2}
      ${parseInt(theme.spacings.x2) - 2}px ${theme.spacings.x2}`};
  }
`;

const BearName = styled.h3`
  && {
    margin: 0;
    font-weight: ${({ theme }) => theme.fonts.weights.regular};
    font-size: ${({ theme }) => theme.fonts.sizes.mobile.regular};
    text-transform: none;
    line-height: 1.33;
    font-family: 'Poppins', sans-serif !important;

    ${breakpoints.MD} {
      font-size: ${({ theme }) => theme.fonts.sizes.tablet.regular};
    }

    ${breakpoints.MDL} {
      font-size: ${({ theme }) => theme.fonts.sizes.tabletLandscape.regular};
    }

    ${breakpoints.LG} {
      font-size: ${({ theme }) => theme.fonts.sizes.desktop.regular};
    }
  }
`;

const BearId = styled.div`
  position: relative;
  top: -1px;
`;

export { BearLink, StyledBearCard, BearImage, BearInfo, BearName, BearId };
