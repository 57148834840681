import styled, { css } from 'styled-components';
import { breakpoints } from '@/styles/breakpoints';

interface StyledStoryCardProps {
  compact: boolean;
}

const StoryImage = styled.div`
  width: 100%;
  height: 332px;
  position: relative;
`;

const StoryContent = styled.div`
  border: 2px solid #fff;
  border-top: none;
  padding: ${({ theme }) =>
    `${parseInt(theme.spacings.x2) + 2}px ${theme.spacings.x2} 
    ${parseInt(theme.spacings.x4) - 3}px`};
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
`;

const StoryFooter = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  margin-top: ${({ theme }) => parseInt(theme.spacings.x4) + 2}px;
`;

const StoryTitle = styled.h3`
  && {
    font-weight: ${({ theme }) => theme.fonts.weights.regular};
    font-size: ${({ theme }) => theme.fonts.sizes.mobile.medium};
    text-transform: none;
    line-height: 1.33;
    font-family: 'Poppins', sans-serif !important;
    padding-right: 25%;

    a {
      font-weight: ${({ theme }) => theme.fonts.weights.regular};
      font-size: ${({ theme }) => theme.fonts.sizes.mobile.medium};
      text-transform: none;
    }

    a:hover {
      color: ${({ theme }) => theme.colors.text.white};
    }
  }
`;

const StoryAttributes = styled.div`
  display: flex;
  flex-flow: row nowrap;

  & > div {
    & + div {
      margin-left: ${({ theme }) => theme.spacings.x6};
    }

    &:first-child {
      ${breakpoints.LG} {
        max-width: 50%;
      }

      ${breakpoints.XXLG} {
        max-width: 40%;
      }
    }
  }
`;

const StoryAttributeLabel = styled.div`
  color: ${({ theme }) => theme.colors.text.grey};
  margin-bottom: ${({ theme }) => theme.spacings.base};

  ${breakpoints.MD} {
    margin-bottom: ${({ theme }) => parseInt(theme.spacings.x2) - 7}px;
  }

  ${breakpoints.XXLG} {
    font-size: 16px;
    margin-bottom: ${({ theme }) => parseInt(theme.spacings.x3) - 6}px;
  }
`;

const StoryAttributeContent = styled.div``;

const StyledStoryCard = styled.article<StyledStoryCardProps>`
  display: flex;
  flex-flow: row wrap;
  width: 100%;

  & > * {
    width: 100%;
  }

  ${({ compact }) =>
    !compact &&
    css`
      ${breakpoints.MD} {
        flex-flow: row nowrap;
      }

      & > * {
        &:first-child {
          ${breakpoints.MD} {
            min-width: 303px;
            width: 303px;
          }

          ${breakpoints.MDL} {
            min-width: 326px;
            width: 326px;
          }

          ${breakpoints.LG} {
            min-width: 326px;
            width: 326px;
          }

          ${breakpoints.XXLG} {
            min-width: 474px;
            width: 474px;
          }
        }
      }

      ${StoryImage} {
        ${breakpoints.MD} {
          width: 303px;
          height: 298px !important;
        }

        ${breakpoints.MDL} {
          width: 326px;
          height: 320px !important;
        }

        ${breakpoints.LG} {
          width: 326px;
          height: 320px !important;
        }

        ${breakpoints.XXLG} {
          width: 474px;
          height: 466px !important;
        }
      }

      ${StoryContent} {
        ${breakpoints.MD} {
          border-left: none;
          border-top: 2px solid #fff;
          display: flex;
          flex-flow: column wrap;
          align-content: flex-start;
          align-items: flex-start;
          justify-content: flex-start;
          padding: ${({ theme }) =>
            `${parseInt(theme.spacings.x4)}px ${theme.spacings.x4} 
    ${parseInt(theme.spacings.x4)}px`};
          height: auto !important;

          & > * {
            width: 100%;
          }
        }

        ${breakpoints.MDL} {
          padding: ${({ theme }) =>
            `${parseInt(theme.spacings.x4) - 3}px ${theme.spacings.x4} 
    ${parseInt(theme.spacings.x4)}px`};
        }

        ${breakpoints.LG} {
          padding: ${({ theme }) =>
            `${parseInt(theme.spacings.x4) - 3}px ${theme.spacings.x4}`};
        }
      }

      ${StoryTitle} {
        ${breakpoints.MD} {
          font-size: ${({ theme }) => theme.fonts.sizes.tablet.large};
          padding-right: 0;
          margin-bottom: ${({ theme }) => parseInt(theme.spacings.x2) + 2}px;
        }

        ${breakpoints.MDL} {
          font-size: ${({ theme }) => theme.fonts.sizes.tabletLandscape.large};
        }

        ${breakpoints.LG} {
          font-size: ${({ theme }) => theme.fonts.sizes.desktop.large};
        }

        ${breakpoints.XXLG} {
          margin-bottom: ${({ theme }) => parseInt(theme.spacings.x4) + 2}px;
          font-size: 32px;
        }
      }

      ${StoryAttributeContent} {
        ${breakpoints.MD} {
          font-size: ${({ theme }) => theme.fonts.sizes.tablet.regular};
          line-height: ${({ theme }) => theme.fonts.lineHeights.xLarge};
        }

        ${breakpoints.XXLG} {
          font-size: 18px;
        }
      }

      ${StoryFooter} {
        ${breakpoints.MD} {
          align-self: flex-end;
          margin-top: auto;
        }
      }
    `}

  ${({ compact }) =>
    compact &&
    css`
      ${StoryContent} {
        ${breakpoints.MD} {
          padding-bottom: 14px;
        }
      }
    `}
`;

const StoryLink = styled.a`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  color: ${({ theme }) => theme.colors.primary.second};
  white-space: nowrap;

  ${breakpoints.XXLG} {
    font-size: 14px;
  }

  span {
    margin-right: 6px;
  }

  svg {
    width: 5px;
    height: 8px;
    transition: transform 0.1s ease-in-out;

    path {
      fill: ${({ theme }) => theme.colors.primary.second};
    }
  }

  &:hover {
    color: ${({ theme }) => theme.colors.primary.second};

    svg {
      transform: translateX(2px);
    }
  }
`;

export {
  StyledStoryCard,
  StoryImage,
  StoryContent,
  StoryAttributes,
  StoryAttributeLabel,
  StoryAttributeContent,
  StoryTitle,
  StoryFooter,
  StoryLink,
};
