import styled from 'styled-components';
import { breakpoints } from '@/styles/breakpoints';

interface NavigationProps {
  vertical?: boolean;
}

const StyledFooterNavigation = styled.div<NavigationProps>`
  ul {
    flex-flow: row nowrap;

    li {
      a {
        padding: 0;
      }

      & + li {
        margin-left: ${({ theme }) => theme.spacings.x4};
      }
    }

    ${breakpoints.MD} {
      flex-flow: row nowrap;

      li {
        & + li {
          margin-left: ${({ theme }) => theme.spacings.x4};
          margin-top: 0;
        }
      }
    }

    ${breakpoints.LG} {
      flex-flow: column wrap;

      li {
        & + li {
          margin-left: 0;
          margin-top: ${({ theme }) => theme.spacings.x3};
        }
      }
    }
  }
`;

export { StyledFooterNavigation };
