import 'modern-css-reset/dist/reset.min.css';
import { ThemeProvider } from 'styled-components';
import '@/styles/grid/grid.scss';
import { GlobalStyle } from '@/styles/global';
import { srbTheme } from '@/styles/theme';
import Cursor from '@/components/Cursor';
import Header from '@/components/Header';
import Footer from '@/components/Footer';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger.js';
import { useStores } from '@/stores';
import { Provider } from 'mobx-react';
import Modal from '@/scenes/Modal';
import Head from 'next/head';
// import CustomEase from 'gsap/dist/CustomEase';
import Preloader from '@/scenes/common/Preloader';
import EGLD from '@/components/EGLD';

if (typeof window !== 'undefined') {
  gsap.registerPlugin(ScrollTrigger);
  // gsap.registerEase('srbEase', CustomEase.create('custom', '0.52, 0.01, 0, 1'));
}

// export function reportWebVitals(metric) {
//   console.log(metric);
// }

const App = ({ Component, pageProps }) => {
  const stores = useStores(pageProps.initialState);

  return (
    <>
      <Head>
        <title>Super Rare Bears</title>
        <meta
          name="description"
          content="WELCOME TO THE SUPER RARE BEARS ZONE There is a dimension, beyond that which is known to bear. It is a dimension as vast as space and as timeless as infinity."
        />
        <link rel="canonical" href="https://superrarebears.com" />
        <meta property="og:title" content="Super Rare Bears" key="title" />
        <meta property="og:locale" content="en-EN" />
        <meta property="og:site_name" content="Super Rare Bears" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:site" content="@SuperRare_Bears" />
        <meta property="twitter:creator" content="@SuperRare_Bears" />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://superrarebears.com" />
        <meta property="og:title" content="Super Rare Bears" />
        <meta
          property="og:description"
          content="is an NFT project currently building on the Elrond blockchain. Unique attributes, inventive staking opportunities and future gamification, ensure these bears are always bullish!"
        />
        <meta property="og:image" content="/OG.jpg" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />

        <meta property="twitter:url" content="https://superrarebears.com" />
        <meta property="twitter:title" content="Super Rare Bears" />
        <meta property="twitter:description" content="Super Rare Bears" />
        <meta property="twitter:image" content="/OG.jpg" />
      </Head>
      <Provider {...stores}>
        <ThemeProvider theme={srbTheme}>
          <GlobalStyle />
          <Cursor />
          <Preloader />
          <Modal />
          <Header />
          <Component {...pageProps} />
          <Footer />
          <EGLD />
        </ThemeProvider>
      </Provider>
    </>
  );
};

export default App;
