import styled from 'styled-components';
import { breakpoints } from '@/styles/breakpoints';

const StyledFooter = styled.footer`
  padding: ${({ theme }) => theme.spacings.x7} 0;

  ${breakpoints.MD} {
    padding-bottom: 32px;
  }

  ${breakpoints.LG} {
    padding-bottom: 0;
  }
`;

const FooterGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 4fr);
  grid-row-gap: ${({ theme }) => theme.spacings.x4};
  grid-template-areas:
    'copyright'
    'subscribe'
    'navigation'
    'socials'
    'zgraya'
    'terms';

  ${breakpoints.MD} {
    grid-template-columns: repeat(12, 12fr);
    grid-row-gap: 50px;
    grid-template-areas:
      'copyright subscribe'
      'navigation terms'
      'socials zgraya';
  }

  ${breakpoints.LG} {
    grid-row-gap: 56px;
    grid-template-areas:
      'copyright navigation subscribe'
      'socials navigation terms'
      'socials . zgraya';
  }

  .copyright {
    grid-area: copyright;
    grid-column: span 4;

    ${breakpoints.MD} {
      grid-column: span 6;
    }

    ${breakpoints.MDL} {
      grid-column: span 6;
    }

    ${breakpoints.LG} {
      grid-column: span 6;
      order: 1;
    }
  }

  .subscribe {
    grid-area: subscribe;
    grid-column: span 4;
    margin-bottom: ${({ theme }) => theme.spacings.x3};

    ${breakpoints.MD} {
      grid-column: span 6;
      margin-bottom: 0;
    }

    ${breakpoints.MDL} {
      grid-column: span 6;
    }

    ${breakpoints.LG} {
      order: 3;
      grid-column: span 4;
    }
  }

  .navigation {
    grid-area: navigation;
    grid-column: span 4;

    ${breakpoints.MD} {
      grid-column-start: 1;
      grid-column-end: 10;
    }

    ${breakpoints.LG} {
      order: 2;
      grid-column: span 2;
    }
  }

  .socials {
    grid-area: socials;
    grid-column: span 4;
    margin-bottom: ${({ theme }) => theme.spacings.base};

    ${breakpoints.MD} {
      padding-left: 0;
      grid-column-start: 1;
      grid-column-end: 9;
      grid-row-start: 3;
      grid-row-end: 3;
      margin-top: auto;
      margin-bottom: 0;
    }

    ${breakpoints.MDL} {
      grid-column-start: 1;
      grid-column-end: 7;
    }

    ${breakpoints.LG} {
      grid-column: span 5;
      grid-row-start: 2;
      grid-row-end: 2;
      margin-top: -12px;
    }
  }

  .zgraya {
    grid-column: span 4;
    line-height: 1;

    a {
      font-size: 12px;
      line-height: 1;
      color: ${({ theme }) => theme.colors.text.grey};
      display: inline-flex;
      align-items: center;
      text-transform: none;

      svg {
        margin-left: 4px;
      }
    }

    ${breakpoints.MD} {
      grid-column-start: 9;
      grid-column-end: 13;
      place-self: end;
      margin-bottom: ${({ theme }) => theme.spacings.x2};
    }

    ${breakpoints.LG} {
      margin-top: -56px;
      position: relative;
      top: -50px;
    }
  }

  .terms {
    grid-area: terms;
    grid-column: span 4;

    ${breakpoints.MD} {
      align-self: end;
      grid-column-start: 7;
      grid-column-end: 13;
      margin-bottom: 6px;
    }

    ${breakpoints.MDL} {
      grid-column-start: 6;
      grid-column-end: 13;
      place-self: end;
    }

    ${breakpoints.LG} {
      grid-column: span 5;
      order: 5;
      place-self: start;
      justify-self: end;
      margin-bottom: 16px;
    }
  }
`;

export { StyledFooter, FooterGrid };
