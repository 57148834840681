import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import qs from 'qs';
import { IBear } from '@/stores/Bears';
import cloneDeep from 'lodash.clonedeep';
import { IBearType, IFilter } from '@/stores/Gallery';
import camelCase from 'lodash.camelcase';
import uniq from 'lodash.uniq';
import { IStory } from '@/stores/Stories';

export interface StrapiMeta {
  pagination: {
    page: number;
    pageSize: number;
    pageCount: number;
    total: number;
  };
}

export interface StrapiResponse<T> {
  data: {
    id: number;
    attributes: T & {
      createdAt: string;
      updatedAt: string;
      publishedAt: string;
    };
  };
  meta: StrapiMeta;
}

export interface StrapiCollectionResponse<T> {
  data: {
    id: number;
    attributes: T & {
      createdAt: string;
      updatedAt: string;
      publishedAt: string;
    };
  }[];
  meta: StrapiMeta;
}

const manipulateBear = (rawBear): IBear => {
  const bear = cloneDeep(rawBear.attributes);

  bear.id = rawBear.id;
  bear.tokenId = parseInt(bear.tokenId);
  bear.rank = parseInt(bear.rank);

  bear.traits = [
    { type: 'Fur', value: bear.fur },
    { type: 'Clothes', value: bear.clothes },
    { type: 'Hats', value: bear.hats },
    { type: 'Eyes', value: bear.eyes },
    { type: 'Mouth', value: bear.mouth },
    { type: 'Items', value: bear.items },
    { type: 'Nose', value: bear.nose },
    { type: 'Masks', value: bear.masks },
    { type: 'Suits', value: bear.suits },
    { type: 'Background', value: bear.background },
    { type: 'Corns', value: bear.corns },
    { type: 'Aliens', value: bear.aliens },
    { type: 'The Ultimate Ultra', value: bear.theUltimateUltra },
    { type: 'Mythical Bear', value: bear.mythicalBear },
    { type: 'Ultra Rare Bear', value: bear.ultraRareBear },
  ];

  bear.traits = bear.traits.filter((trait) => trait.value);

  if (bear.story.data) {
    bear.story = {
      id: bear.story.data.id,
      name: bear.story.data.attributes.bearName,
      slug: bear.story.data.attributes.slug,
    };
  } else {
    bear.story = null;
  }

  return bear;
};

const manipulateStory = (rawStory): IStory => {
  return {
    id: rawStory.id,
    ...cloneDeep(rawStory.attributes),
    bear: manipulateBear({
      attributes: {
        ...cloneDeep(rawStory.attributes.bear.data.attributes),
        story: {
          data: null,
        },
      },
    }),
  };
};

class StrapiService {
  private axiosInstance: AxiosInstance | null;

  constructor() {
    this.axiosInstance = axios.create({
      baseURL:
        typeof window === 'undefined'
          ? `${process.env.STRAPI_URL}/api`
          : '/api/strapi',
    });

    this.axiosInstance.interceptors.request.use(
      async (config) => {
        config.headers = {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        };

        return config;
      },
      (error) => {
        Promise.reject(error);
      },
    );
  }

  public async get<T>(url: string, config?: AxiosRequestConfig) {
    return this.axiosInstance.get(url, config);
  }

  public async post<T>(url: string, config?: AxiosRequestConfig) {
    return this.axiosInstance.post(url, config);
  }

  public async getFeaturedBears(): Promise<IBear[]> {
    const query = qs.stringify(
      {
        filters: {
          featured: {
            $eq: true,
          },
        },
        populate: '*',
        pagination: {
          page: 1,
          pageSize: 100,
        },
      },
      {
        encodeValuesOnly: true,
      },
    );
    const { data } = await this.get<StrapiCollectionResponse<IBear>>(
      `/bears?${query}`,
    );

    return data.data.map((rawBear) => manipulateBear(rawBear));
  }

  public async getGalleryBears(
    skip: number,
    filters?: any,
  ): Promise<{ bears: IBear[]; total: number }> {
    const pageSize = 12;
    const page = skip ? skip / pageSize + 1 : 1;

    const query = qs.stringify(
      {
        filters,
        populate: '*',
        pagination: {
          page,
          pageSize,
        },
      },
      {
        encodeValuesOnly: true,
      },
    );
    const { data } = await this.get<StrapiCollectionResponse<IBear>>(
      `/bears?${query}`,
    );

    return {
      bears: data.data.map((rawBear) => manipulateBear(rawBear)),
      total: data.meta.pagination.total,
    };
  }

  public async getGalleryTypes(): Promise<IBearType[]> {
    const query = qs.stringify(
      {
        filters: {
          rank: {
            $lt: 3,
          },
        },
        populate: '*',
      },
      {
        encodeValuesOnly: true,
      },
    );

    const { data } = await this.get<StrapiCollectionResponse<IBear>>(`/bears`);
    const urBearsResponse = await this.get<StrapiCollectionResponse<IBear>>(
      `/bears?${query}`,
    );

    const all: IBearType = {
      label: 'All',
      value: '',
      active: true,
      count: data.meta.pagination.total,
    };

    const urb: IBearType = {
      label: 'URB',
      value: qs.stringify({
        rank: {
          $lt: 3,
        },
      }),
      active: false,
      count: urBearsResponse.data.meta.pagination.total,
    };

    const srb: IBearType = {
      label: 'SRB',
      value: qs.stringify({
        rank: {
          $gt: 2,
        },
      }),
      active: false,
      count:
        data.meta.pagination.total - urBearsResponse.data.meta.pagination.total,
    };

    return [all, srb, urb];
  }

  public async getGalleryAvailableFilters(): Promise<IFilter[]> {
    const bears = await this.getAllBears();

    const filters = [
      { type: 'Fur', options: [], expanded: false },
      { type: 'Clothes', options: [], expanded: false },
      { type: 'Hats', options: [], expanded: false },
      { type: 'Eyes', options: [], expanded: false },
      { type: 'Mouths', options: [], expanded: false },
      { type: 'Items', options: [], expanded: false },
      { type: 'Nose', options: [], expanded: false },
      { type: 'Masks', options: [], expanded: false },
      { type: 'Suits', options: [], expanded: false },
      { type: 'Background', options: [], expanded: false },
      { type: 'Badge', options: [], expanded: false },
      { type: 'Bearception', options: [], expanded: false },
      { type: 'Ultra Rare Bear', options: [], expanded: false },
      { type: 'Mythical Bear', options: [], expanded: false },
      { type: 'The Ultimate Ultra', options: [], expanded: false },
    ];

    filters.map((_filter) => {
      const filter = _filter;

      const filterType = camelCase(filter.type);

      filter.options = uniq(
        bears
          .filter((_bear) => _bear[filterType])
          .map((_bear) => {
            return _bear[filterType];
          }),
      ).map((_option) => ({
        value: _option,
        total: bears.filter((_bear) => _bear[filterType] === _option).length,
        active: false,
      }));

      return filter;
    });

    // @ts-ignore
    return filters;
  }

  public async getAllBears(): Promise<IBear[]> {
    const query = qs.stringify(
      {
        populate: '*',
        pagination: {
          page: 1,
          pageSize: 10000,
        },
      },
      {
        encodeValuesOnly: true,
      },
    );

    const { data } = await this.get<StrapiCollectionResponse<IBear>>(
      `/bears?${query}`,
    );

    return data.data.map((rawBear) => manipulateBear(rawBear));
  }

  public async getAllStories(): Promise<IStory[]> {
    const query = qs.stringify(
      {
        populate: '*',
        pagination: {
          page: 1,
          pageSize: 10000,
        },
      },
      {
        encodeValuesOnly: true,
      },
    );

    const { data } = await this.get<StrapiCollectionResponse<IStory>>(
      `/stories?${query}`,
    );

    return data.data.map((rawStory) => manipulateStory(rawStory));
  }

  public async getSingleBear(id: string): Promise<IBear> {
    const query = qs.stringify(
      {
        filters: {
          identifier: {
            $eq: id,
          },
        },
        populate: '*',
      },
      {
        encodeValuesOnly: true,
      },
    );

    try {
      const { data } = await this.get<StrapiResponse<IBear>>(`/bears?${query}`);
      return manipulateBear(data.data[0]);
    } catch (e) {
      console.log(e);
    }
  }

  public async getBearByToken(token: number): Promise<IBear> {
    const query = qs.stringify(
      {
        filters: {
          tokenId: {
            $eq: token,
          },
        },
        populate: '*',
      },
      {
        encodeValuesOnly: true,
      },
    );

    try {
      const { data } = await this.get<StrapiResponse<IBear>>(`/bears?${query}`);
      return manipulateBear(data.data[0]);
    } catch (e) {
      console.log(e);
    }
  }

  public async getSingleStory(slug: string): Promise<IStory> {
    const query = qs.stringify(
      {
        filters: {
          slug: {
            $eq: slug,
          },
        },
        populate: '*',
      },
      {
        encodeValuesOnly: true,
      },
    );

    try {
      const { data } = await this.get<StrapiResponse<IBear>>(
        `/stories?${query}`,
      );
      return manipulateStory(data.data[0]);
    } catch (e) {
      console.log(e);
    }
  }

  public async getStories(
    skip: number,
  ): Promise<{ items: IStory[]; total: number }> {
    const pageSize = 6;
    const page = skip ? skip / pageSize + 1 : 1;

    const query = qs.stringify(
      {
        sort: ['publishedAt:desc'],
        populate: '*',
        pagination: {
          page,
          pageSize,
        },
      },
      {
        encodeValuesOnly: true,
      },
    );

    try {
      const { data } = await this.get<StrapiCollectionResponse<IStory>>(
        `/stories?${query}`,
      );

      return {
        items: data.data.map((item) => manipulateStory(item)),
        total: data.meta.pagination.total,
      };
    } catch (e) {
      console.log(e);
    }
  }

  public async createStory(story): Promise<IStory> {
    try {
      const { data } = await this.post<StrapiResponse<IBear>>(`/stories`, {
        data: story,
      });
      return manipulateStory(data.data[0]);
    } catch (e) {
      console.log(e);
    }
  }
}

export default StrapiService;
