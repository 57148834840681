import { applySnapshot, getEnv, Instance, types } from 'mobx-state-tree';
import { flow } from 'mobx-state-tree';

const relatedStory = types.model('Related Story', {
  id: types.number,
  name: types.string,
  slug: types.string,
});

const TraitModel = types.model('Trait', {
  type: types.string,
  value: types.string,
});

export const BearModel = types.model('Bear', {
  id: types.maybeNull(types.number),
  tokenId: types.maybeNull(types.number),
  identifier: types.string,
  image: types.string,
  rank: types.number,
  rarityScore: types.number,
  story: types.maybeNull(relatedStory),
  traits: types.array(TraitModel),
  featured: types.boolean,
});

export const BearsStoreModel = types
  .model('BearsStore', {
    featuredBears: types.array(BearModel),
    items: types.map(BearModel),
  })
  .actions((self) => {
    const setItems = flow(function* setItems(bears: IBear[]) {
      bears.forEach((item) => {
        self.items.set(item.identifier, item);
      });
    });

    const loadFeaturedBears = flow(function* loadFeaturedBears() {
      try {
        const bears = yield getEnv(self).strapiService.getFeaturedBears();

        // @ts-ignore
        yield self.setItems(bears);

        self.featuredBears = bears;
      } catch (error) {
        console.log(error);
      }
    });

    return { loadFeaturedBears, setItems };
  });

export type IBearsStore = Instance<typeof BearsStoreModel>;
export type IBear = Instance<typeof BearModel>;

let bearsStore: IBearsStore;

export const initializeBearsStore = (snapshot = null) => {
  const _bearsStore = bearsStore ?? BearsStoreModel.create();

  if (snapshot) {
    applySnapshot(_bearsStore, snapshot);
  }

  if (typeof window === 'undefined') return _bearsStore;

  if (!bearsStore) bearsStore = _bearsStore;

  return bearsStore;
};
