import styled, { css } from 'styled-components';
import { breakpoints } from '@/styles/breakpoints';

interface BadgeProps {
  secondary: boolean;
  withIcon: boolean;
}

const StyledBadge = styled.div<BadgeProps>`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  align-content: center;
  padding: 1px 5px 0;
  line-height: 0;
  border: 1px solid ${({ theme }) => theme.colors.primary.first};
  border-radius: 100px;
  font-size: 0;
  transition: border-color 0.15s ease-in-out;
  will-change: border-color;

  ${({ secondary, theme }) =>
    secondary &&
    css`
      border-color: ${theme.colors.secondary.second};
    `}

  ${({ withIcon }) =>
    withIcon &&
    css`
      padding-right: 6px;
    `}
`;

const BadgeIcon = styled.span`
  margin-right: 4px;
`;

const BadgeText = styled.span<BadgeProps>`
  line-height: 1;
  color: ${({ theme }) => theme.colors.primary.first};
  transition: color 0.15s ease-in-out;
  will-change: color;
  white-space: nowrap;

  ${({ secondary, theme }) =>
    secondary &&
    css`
      color: ${theme.colors.secondary.second};
    `}

  ${breakpoints.XXLG} {
    font-size: 16px;
  }
`;

export { StyledBadge, BadgeIcon, BadgeText };
