import styled, { css } from 'styled-components';
import { breakpoints } from '@/styles/breakpoints';

const DropdownMenu = styled.div`
  display: flex;
  flex-flow: row wrap;
  transform-origin: top center;
  transition: all 0.15s ease-in-out;
  transform: scaleY(0);
  position: relative;
  opacity: 0;

  ${breakpoints.MD} {
    position: absolute;
    top: calc(100% + 20px);
    left: -60%;
    background: #090909;
    border: 2px solid #ffffff;
    border-radius: 24px;
    width: 202px;
    padding: 12px 24px;
  }
`;

const StyledDropdown = styled.div<{ open: boolean }>`
  position: relative;

  ${({ open }) =>
    open &&
    css`
      ${DropdownMenu} {
        transform: scaleY(1);
        opacity: 1;
      }
    `}

  ${breakpoints.MD} {
    margin-left: 24px;
  }

  ${breakpoints.MDL} {
    margin-left: 56px;
  }

  ${breakpoints.LG} {
    margin-left: 0;
  }
`;

const StyledHeaderNav = styled.div`
  display: none;

  ${breakpoints.MD} {
    display: flex;
    align-items: center;
  }

  ${breakpoints.LG} {
    margin-left: 160px;

    ${StyledDropdown} {
      display: none;
    }
  }

  nav {
    li:first-child {
      display: none;

      ${breakpoints.MDL} {
        display: block;
      }

      ${breakpoints.LG} {
        display: block;
      }
    }
  }
`;

const DropdownHeader = styled.div<{ open: boolean }>`
  font-size: 58px;
  line-height: 50px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  font-family: PPFormula, sans-serif;
  border-top: 2px solid #3a3a3a;
  padding: 32px 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  align-content: center;

  span {
    position: relative;
    top: 6px;

    ${breakpoints.MD} {
      top: 0;
    }
  }

  svg {
    margin-left: 16px;
    transform: rotate(-180deg);
    transition: transform 0.2s ease-in-out;

    ${breakpoints.MD} {
      height: 7px;
      width: 7px;
      margin-left: 4px;
      position: relative;
      top: -1px;
    }
  }

  ${breakpoints.MD} {
    border-top: 0;
    padding: 0;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    font-family: 'Poppins', sans-serif;
  }

  ${({ open }) =>
    open &&
    css`
      svg {
        transform: rotate(0);
      }
    `}
`;

const DropdownLink = styled.a`
  display: flex;
  width: 100%;
  align-content: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.002em;
  text-transform: uppercase;
  padding: 16px 0;
  position: relative;

  ${breakpoints.MD} {
    justify-content: space-between;
  }

  svg {
    margin-left: 17px;

    path,
    line {
      transition: stroke 0.15s ease-in-out;
    }
  }

  &:hover {
    color: ${({ theme }) => theme.colors.primary.first};

    svg {
      path,
      line {
        stroke: ${({ theme }) => theme.colors.primary.first};
      }
    }
  }

  & + & {
    &:before {
      content: '';
      position: absolute;
      top: -1px;
      left: 0;
      width: 16px;
      height: 2px;
      background-color: #3a3a3a;

      ${breakpoints.MD} {
        width: 100%;
      }
    }
  }
`;

export {
  StyledHeaderNav,
  StyledDropdown,
  DropdownHeader,
  DropdownMenu,
  DropdownLink,
};
