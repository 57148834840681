import styled from 'styled-components';
import { breakpoints } from '@/styles/breakpoints';

const GreetingScreen = styled.div`
  padding: 20px;
  position: relative;
  height: 100%;

  ${breakpoints.MD} {
    padding: ${({ theme }) => theme.spacings.x5}
      ${({ theme }) => theme.spacings.x7} ${({ theme }) => theme.spacings.x13};
    height: 420px;
  }
`;

const Title = styled.h4`
  && {
    text-align: center;
    margin-bottom: ${({ theme }) => theme.spacings.x4};
  }
`;

const Action = styled.div`
  & > * {
    margin: 0 auto;
  }
`;

const Bear = styled.div`
  position: absolute;
  bottom: -7px;
`;

const LeftBear = styled(Bear)`
  left: 0;
`;
const RightBear = styled(Bear)`
  right: 0;
`;

const Background = styled.div`
  position: absolute;
  bottom: -6px;
  left: 0;
  right: 0;
`;

export { GreetingScreen, Title, Action, LeftBear, RightBear, Background };
