import {
  applySnapshot,
  cast,
  flow,
  getEnv,
  getParent,
  Instance,
  types,
} from 'mobx-state-tree';
import qs from 'qs';
import { BearModel } from '@/stores/Bears';
import { IRootStore } from '@/stores/index';
import isEmpty from 'lodash.isempty';
import camelcase from 'camelcase';

const FilterOption = types.model('Filter Option', {
  value: types.string,
  total: types.number,
  active: types.boolean,
});

export type IFilterOption = Instance<typeof FilterOption>;

const Filter = types.model('Gallery Filter', {
  type: types.string,
  options: types.array(FilterOption),
  expanded: types.boolean,
});

export type IFilter = Instance<typeof Filter>;

const BearType = types.model('Bear Type', {
  label: types.string,
  count: types.number,
  active: types.boolean,
  value: types.string,
});

export type IBearType = Instance<typeof BearType>;

const manipulateFilters = (filters) => {
  const result = filters
    .map((f) => {
      if (f.options.length > 1) {
        return [
          '$or',
          f.options.map((o) => ({
            [camelcase(f.type)]: {
              $eq: o.value,
            },
          })),
        ];
      } else {
        return [camelcase(f.type), { $eq: f.options[0].value }];
      }
    })
    .reduce(
      (acc, filter) => {
        const result = acc;

        if (filter[0] === '$or') {
          result.$or.push(...filter[1]);
        } else {
          result[filter[0]] = filter[1];
        }

        return result;
      },
      {
        $or: [],
      },
    );

  if (!result.$or.length) {
    delete result.$or;
  }

  return result;
};

export const GalleryStoreModel = types
  .model('Gallery Store', {
    types: types.array(BearType),
    search: types.string,
    filters: types.array(Filter),
    bears: types.array(BearModel),
    loading: types.boolean,
    loadingMore: types.boolean,
    currentTotal: types.number,
  })
  .actions((self) => {
    const actionsActiveFilters = () => {
      return manipulateFilters(
        self.filters
          .map((group) => {
            const _group = { ...group };

            // @ts-ignore
            _group.options = _group.options.filter((option) => option.active);

            return _group;
          })
          .filter((g) => g.options.length),
      );
    };

    const loadBears = flow(function* loadBears(
      reset?: boolean,
      more?: boolean,
    ) {
      if (!more) {
        self.loading = true;
      } else {
        self.loadingMore = true;
      }

      const type = self.types.find((type) => type.active).value;

      let s: any = {};

      if (self.search) {
        s.tokenId = {
          $eq: self.search,
        };
      }

      const activeFilters = actionsActiveFilters();

      const filters = {
        ...activeFilters,
        ...qs.parse(type),
        ...s,
      };

      if (reset) {
        self.bears = cast([]);
      }

      const { bears, total } = yield getEnv(self).strapiService.getGalleryBears(
        self.bears.length,
        filters,
      );

      yield getParent<IRootStore>(self).bearsStore.setItems(bears);

      if (reset) {
        self.bears = cast([]);
      }

      self.bears.push(...bears);

      // @ts-ignore
      self.setCurrentTotal(total);

      self.loading = false;
      self.loadingMore = false;
    });

    const loadTypes = flow(function* loadTypes() {
      self.types = yield getEnv(self).strapiService.getGalleryTypes();
    });

    const selectType = flow(function* selectType(
      type: IBearType,
      dry?: boolean,
    ) {
      const _types = self.types;

      _types.map((t) => {
        const _type = t;

        _type.active = false;

        return _type;
      });

      _types[_types.findIndex((t) => t.label === type.label)].active = true;

      self.types = _types;

      if (!dry) {
        // @ts-ignore
        self.loadBears(true);
      }
    });

    const setSearch = flow(function* setSearch(s: string, dry?: boolean) {
      self.search = s;

      if (!dry) {
        // @ts-ignore
        self.loadBears(true);
      }
    });

    const setCurrentTotal = flow(function* setCurrentTotal(total: number) {
      self.currentTotal = total;
    });

    const loadFilters = flow(function* loadFilters() {
      self.filters = yield getEnv(
        self,
      ).strapiService.getGalleryAvailableFilters();
    });

    const toggleFilterGroup = flow(function* toggleFilterGroup(
      filter: IFilter,
    ) {
      // @ts-ignore
      self.filters = self.filters.map((_filter) => {
        const f = _filter;

        if (f.type === filter.type) {
          f.expanded = !f.expanded;
        } else {
          f.expanded = false;
        }

        return f;
      });
    });

    const toggleFilter = flow(function* toggleFilter(
      group: IFilter,
      filter: IFilterOption,
      state: boolean,
      dry?: boolean,
    ) {
      // @ts-ignore
      self.filters = self.filters.map((g) => {
        const _group = g;

        if (_group.type === group.type) {
          // @ts-ignore
          _group.options = _group.options.map((o) => {
            const _option = o;

            if (_option.value === filter.value) {
              _option.active = state;
            }

            return _option;
          });
        }

        return _group;
      });

      if (!dry) {
        // @ts-ignore
        self.loadBears(true);
      }
    });

    const resetFilters = flow(function* resetFilters() {
      const activeFilters = actionsActiveFilters();

      // @ts-ignore
      self.filters = self.filters.map((g) => {
        const _group = g;

        _group.expanded = false;

        // @ts-ignore
        _group.options = _group.options.map((o) => {
          const _option = o;
          _option.active = false;
          return _option;
        });

        return _group;
      });

      if (isEmpty(activeFilters)) return;
      // @ts-ignore
      self.loadBears(true);
    });

    return {
      loadBears,
      loadTypes,
      selectType,
      setSearch,
      setCurrentTotal,
      loadFilters,
      toggleFilterGroup,
      toggleFilter,
      resetFilters,
    };
  })
  .views((self) => ({
    get activeType() {
      return self.types.find((type) => type.active);
    },

    get activeFilters() {
      return self.filters
        .map((group) => {
          const _group = { ...group };

          // @ts-ignore
          _group.options = _group.options.filter((option) => option.active);

          return _group;
        })
        .filter((g) => g.options.length);
    },

    get queryString() {
      const type = this.activeType.value;

      let s: any = {};

      if (self.search) {
        s.tokenId = {
          $eq: self.search,
        };
      }

      const activeFilters = manipulateFilters(this.activeFilters);

      return {
        type: {
          ...qs.parse(type),
        },
        ...s,
        filters: Object.keys(activeFilters).length ? activeFilters : null,
      };
    },
  }));

export type IGalleryStore = Instance<typeof GalleryStoreModel>;

let galleryStore: IGalleryStore;

export const initializeGalleryStore = (snapshot = null) => {
  const _galleryStore =
    galleryStore ??
    GalleryStoreModel.create({
      bears: [],
      loading: false,
      loadingMore: false,
      types: [
        {
          label: 'All',
          active: true,
          count: 0,
          value: '',
        },
        {
          label: 'SRB',
          active: false,
          count: 0,
          value: '',
        },
        {
          label: 'URB',
          active: false,
          count: 0,
          value: '',
        },
      ],
      filters: [],
      search: '',
      currentTotal: 0,
    });

  if (snapshot) {
    applySnapshot(_galleryStore, snapshot);
  }

  if (typeof window === 'undefined') return _galleryStore;

  if (!galleryStore) galleryStore = _galleryStore;

  return galleryStore;
};
