import { BadgeIcon, BadgeText, StyledBadge } from './styles';
import { PropsWithChildren, ReactElement } from 'react';

interface BadgeProps {
  icon?: ReactElement;
  secondary?: boolean;
}

const Badge = ({
  children,
  icon,
  secondary,
}: PropsWithChildren<BadgeProps>) => {
  return (
    <StyledBadge secondary={secondary} withIcon={!!icon}>
      {icon && <BadgeIcon>{icon}</BadgeIcon>}
      <BadgeText className="text-small" secondary={secondary}>
        {children}
      </BadgeText>
    </StyledBadge>
  );
};

export default Badge;
