import {
  Action,
  Background,
  GreetingScreen,
  LeftBear,
  RightBear,
  Title,
} from '@/scenes/Stories/CreateModal/Greeting/styles';
import { inject, observer } from 'mobx-react';
import { IUIStore } from '@/stores/UI';
import { FC } from 'react';
import Button from '@/components/UI/Button';

interface GreetingProps {
  uiStore: IUIStore;
}

import Bear1 from '@/assets/create-story/bear1.svg';
import Bear2 from '@/assets/create-story/bear2.svg';
import BG from '@/assets/create-story/bg.svg';

const Greeting: FC<any> = ({ uiStore }: GreetingProps) => {
  return (
    <GreetingScreen>
      <Title>
        Your story will be published right after Panda checks your fur and
        claws.
      </Title>
      <Action>
        <Button onClick={uiStore.closeModal} type="primary">
          got ya
        </Button>
      </Action>
      <Background>
        <BG />
      </Background>
      <LeftBear>
        <Bear1 />
      </LeftBear>
      <RightBear>
        <Bear2 />
      </RightBear>
    </GreetingScreen>
  );
};

export default inject('uiStore')(observer(Greeting));
