import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;

  & > * {
    width: 100%;
  }
`;

const Label = styled.div`
  margin-bottom: ${({ theme }) => theme.spacings.base};
`;

interface InputProps {
  error?: string;
  rounded?: boolean;
  small?: boolean;
  focused: boolean;
}

const StyledInput = styled.div<InputProps>`
  position: relative;

  svg {
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
    pointer-events: none;

    ${({ focused }) =>
      focused &&
      css`
        path {
          stroke: ${({ theme }) => theme.colors.text.white};
        }
      `}

    & + input,
    & + textarea {
      padding-left: 36px;
    }
  }

  input,
  textarea {
    display: block;
    width: 100%;
    height: ${({ theme }) => parseInt(theme.spacings.x6) - 2}px;
    border-radius: ${({ theme }) => theme.spacings.base};
    box-shadow: none;
    border: ${({ theme }) => parseInt(theme.spacings.base) / 4}px solid
      ${({ theme }) => theme.colors.secondary.second};
    outline: none;
    padding: ${({ theme }) => parseInt(theme.spacings.base) * 1.5}px;
    background-color: transparent;
    color: ${({ theme }) => theme.colors.text.white};
    font-size: ${({ theme }) => theme.fonts.sizes.mobile.medium};
    letter-spacing: 0.002em;

    ::placeholder {
      color: ${({ theme }) => theme.colors.secondary.second};
      line-height: ${({ theme }) => parseInt(theme.spacings.x6) - 2}px;
    }

    &:focus {
      ${({ error }) =>
        !error &&
        css`
          border-color: ${({ theme }) => theme.colors.text.white};
        `};
    }

    &[disabled] {
      pointer-events: none;
      border-color: ${({ theme }) => theme.colors.divider};

      ::placeholder {
        color: ${({ theme }) => theme.colors.divider};
      }
    }

    ${({ error }) =>
      error &&
      css`
        border-color: ${({ theme }) => theme.colors.primary.first};
      `};

    ${({ rounded, theme }) =>
      rounded &&
      css`
        line-height: 1;
        height: ${parseInt(theme.spacings.x5) - 2}px;
        border-radius: ${parseInt(theme.spacings.x5) + 4}px;
      `};

    ${({ small, theme }) =>
      small &&
      css`
        font-size: ${theme.fonts.sizes.mobile.small};
        line-height: 1;
        height: ${parseInt(theme.spacings.x4) + 2}px;

        ::placeholder {
          font-size: ${theme.fonts.sizes.mobile.small};
        }
      `};
  }

  textarea {
    height: 180px;
    resize: none;

    ::placeholder {
      color: ${({ theme }) => theme.colors.secondary.second};
      line-height: 1.5;
    }
  }
`;

const Error = styled.span`
  position: absolute;
  left: 0;
  top: calc(100% + ${({ theme }) => theme.spacings.base});
  color: ${({ theme }) => theme.colors.primary.first};
`;

interface ActionProps {
  small?: boolean;
}

const Action = styled.span<ActionProps>`
  position: absolute;
  right: 0;
  top: 0;

  button,
  a {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    height: ${({ theme }) => parseInt(theme.spacings.x5) - 2}px;
    background-color: ${({ theme }) => theme.colors.primary.second};
    color: ${({ theme }) => theme.colors.text.dark};
    padding-left: ${({ theme }) => theme.spacings.x2};
    padding-right: ${({ theme }) => theme.spacings.x2};

    &[disabled] {
      background-color: ${({ theme }) => theme.colors.secondary.second};
    }

    &:hover:enabled,
    &:not([disabled]):hover {
      background-color: ${({ theme }) => theme.colors.bg};
      color: ${({ theme }) => theme.colors.text.white};
    }

    &:active:enabled,
    &:not([disabled]):active {
      background-color: ${({ theme }) => theme.colors.primary.second};
      color: ${({ theme }) => theme.colors.text.dark};
    }

    ${({ small, theme }) =>
      small &&
      css`
        height: ${parseInt(theme.spacings.x4) + 2}px;
      `};
  }
`;

export { Wrapper, Label, StyledInput, Error, Action };
