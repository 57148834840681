import { FC } from 'react';
import { NavigationList } from '@/components/UI/Navigation/styles';
import NavigationItem, {
  INavigationItem,
} from '@/components/UI/Navigation/Item/NavigationItem';

interface IOwnProps {
  items: INavigationItem[];
  activeItem?: string;
  vertical?: boolean;
  small?: boolean;
}

const Navigation: FC<IOwnProps> = ({ items, activeItem, small }) => {
  return (
    <nav>
      <NavigationList small={small}>
        {items.map((item, key) => (
          <li key={key.toString()}>
            <NavigationItem active={activeItem === item.url} {...item} />
          </li>
        ))}
      </NavigationList>
    </nav>
  );
};

export default Navigation;
