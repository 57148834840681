import { FooterGrid, StyledFooter } from '@/components/Footer/styles';
import Copyright from '@/components/Copyright';
// import NewsletterSubscribe from '@/components/NewsletterSubscribe';
import FooterNavigation from '@/components/Footer/Navigation';
import Socials from '@/components/Socials';
// import TermsNavigation from '@/components/Footer/TermsNavigation';

const Footer = () => {
  return (
    <StyledFooter>
      <div className="container">
        <FooterGrid>
          <div className="copyright">
            <Copyright />
          </div>
          <div className="subscribe">{/*<NewsletterSubscribe />*/}</div>
          <div className="navigation">
            <FooterNavigation />
          </div>
          <div className="socials">
            <Socials />
          </div>
          <div className="zgraya">
            <a href="https://zgraya.digital" rel="noreferrer" target="_blank">
              <span>made by</span>
              <svg
                width="50"
                height="13"
                viewBox="0 0 50 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M30.1519 5.06909C29.4977 4.37127 28.4603 4 27.1647 4C25.2278 4 23.5835 4.8499 23.5835 7.2341C23.5835 9.61829 25.2278 10.4592 27.1647 10.4592C28.4603 10.4592 29.502 10.0969 30.1519 9.39016V10.3564H31.9081V4.10736H30.1519V5.06909ZM27.7458 8.94284C26.1833 8.94284 25.3268 8.46421 25.3268 7.2341C25.3268 5.99503 26.1833 5.5164 27.7458 5.5164C29.3083 5.5164 30.1519 5.99503 30.1519 7.2341C30.1519 8.46421 29.304 8.94284 27.7458 8.94284Z"
                  fill="#A9A9A9"
                />
                <path
                  d="M47.7437 4.10736V5.07356C47.0938 4.37127 46.0521 4 44.7565 4C42.8196 4 41.1753 4.8499 41.1753 7.2341C41.1753 9.61829 42.8196 10.4592 44.7565 10.4592C46.0521 10.4592 47.0938 10.0969 47.7437 9.39016V10.3564H49.4999V4.10736H47.7437ZM45.3376 8.94284C43.7751 8.94284 42.9186 8.46421 42.9186 7.2341C42.9186 5.99503 43.7751 5.5164 45.3376 5.5164C46.9001 5.5164 47.7437 5.99503 47.7437 7.2341C47.7437 8.46421 46.9001 8.94284 45.3376 8.94284Z"
                  fill="#A9A9A9"
                />
                <path
                  d="M0 5.62285H4.49804L0 8.83458V10.351H7.08065V8.83458H2.58261L7.08065 5.62285V4.10645H0V5.62285Z"
                  fill="#A9A9A9"
                />
                <path
                  d="M14.1483 5.11382C13.55 4.41153 12.5384 4 11.1094 4C9.04762 4 7.57983 4.93489 7.57983 7.14016C7.57983 9.34543 9.04762 10.2803 11.1094 10.2803C12.5255 10.2803 13.537 9.86879 14.1483 9.1665V11.4881L8.22118 11.4523V13H15.9044V4.10736H14.1483V5.11382ZM11.7421 8.75944C10.0979 8.75944 9.32309 8.28082 9.32309 7.13569C9.32309 5.99056 10.0979 5.51193 11.7421 5.51193C13.3864 5.51193 14.1483 5.99056 14.1483 7.13569C14.1483 8.28082 13.3864 8.75944 11.7421 8.75944Z"
                  fill="#A9A9A9"
                />
                <path
                  d="M18.526 5.1675V4.10736H16.7827V10.3519H18.526V6.60785C19.5892 5.74901 20.7083 5.5164 21.6897 5.5164C22.2019 5.5164 22.6582 5.57903 23.2306 5.72664V4.21024C22.8217 4.10736 22.3224 4 21.6079 4C20.4845 4 19.4256 4.32654 18.526 5.1675Z"
                  fill="#A9A9A9"
                />
                <path
                  d="M36.8191 9.25953L34.2494 4.10645H32.3081L35.4202 10.351H36.3198L35.1059 12.9991H37.0343L41.0933 4.10645H39.1649L36.8191 9.25953Z"
                  fill="#A9A9A9"
                />
              </svg>
            </a>
          </div>
          <div className="terms">{/*<TermsNavigation />*/}</div>
        </FooterGrid>
      </div>
    </StyledFooter>
  );
};

export default Footer;
