import Navigation from '@/components/UI/Navigation';
import { mainNav } from '@/components/Header/HeaderNavigation/HeaderNavigation';
import { StyledFooterNavigation } from '@/components/Footer/Navigation/styles';

const FooterNavigation = () => {
  return (
    <StyledFooterNavigation>
      <Navigation items={mainNav} />
    </StyledFooterNavigation>
  );
};

export default FooterNavigation;
