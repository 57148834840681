import styled, { css } from 'styled-components';
import { breakpoints } from '@/styles/breakpoints';

const StyledModal = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 101;
`;

const ModalOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  opacity: 0.8;
`;

interface ModalWrapperProps {
  small: boolean;
}

const ModalWrapper = styled.div<ModalWrapperProps>`
  background-color: ${({ theme }) => theme.colors.bg};
  padding-top: ${({ theme }) => parseInt(theme.spacings.x7) - 2}px;
  border: 2px solid #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  overflow: hidden;

  ${breakpoints.MD} {
    width: calc(100vw - 80px);
    padding-top: ${({ theme }) => parseInt(theme.spacings.x4)}px;
    height: unset;
  }

  ${breakpoints.MDL} {
    width: calc(100vw - 120px);
    padding-top: ${({ theme }) => parseInt(theme.spacings.x2)}px;
  }

  ${breakpoints.LG} {
    width: 1100px;
  }

  ${({ small }) =>
    small &&
    css`
      ${breakpoints.MD} {
        width: 562px;
        max-height: 90vh;
      }

      ${ModalContent} {
        ${breakpoints.MD} {
          max-height: 90vh;
        }
      }
    `}
`;

const ModalContent = styled.div`
  width: 100%;
  height: 100%;

  ${breakpoints.MD} {
    height: unset;
    max-height: unset;
  }
`;

const CloseModal = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  height: 24px;
  width: 24px;
  transform: rotate(-45deg);

  &:before,
  &:after {
    content: '';
    position: absolute;
    background-color: #fff;
    border-radius: 1px;
    transition: background-color 0.1s ease-in-out;
  }

  &:before {
    height: 2px;
    width: 12px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &:after {
    width: 2px;
    height: 12px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:hover {
    &:before,
    &:after {
      background-color: ${({ theme }) => theme.colors.secondary.second};
    }
  }

  &:active {
    &:before,
    &:after {
      background-color: ${({ theme }) => theme.colors.primary.first};
    }
  }
`;

export { StyledModal, ModalOverlay, ModalWrapper, ModalContent, CloseModal };
