import { SVGLogo } from '@/components/Logo/styles';

const Logo = () => {
  return (
    <SVGLogo viewBox="0 0 86 49" width="86" height="49">
      <path
        d="M12.1078 11.2556L15.8379 10.715C15.9729 10.6955 16.093 10.8028 16.0884 10.9393L16.0516 12.0429C16.0419 12.3335 16.3065 12.5565 16.5913 12.498L17.8676 12.2352C18.0763 12.1921 18.2262 12.0084 18.2262 11.7951V9.48316C18.2262 9.16149 17.9391 8.9158 17.6213 8.96546L14.5411 9.44622C13.0028 9.6864 11.6133 8.49691 11.6133 6.94V3.08602C11.6133 1.38164 12.9949 3.62396e-05 14.6993 3.62396e-05H19.9414C21.414 3.62396e-05 22.6048 1.19933 22.5941 2.67184L22.5845 4.02612C22.5825 4.30174 22.3669 4.5284 22.0918 4.54412L18.5515 4.74636C18.2874 4.76147 18.0649 4.55133 18.0649 4.28672V3.2383C18.0649 3.00673 17.8655 2.82542 17.635 2.84745L16.388 2.96618C16.1865 2.98541 16.0326 3.1546 16.0326 3.35704V5.93904C16.0326 6.19814 16.2533 6.40228 16.5116 6.38236L20.1215 6.10333C21.5177 5.99541 22.71 7.09919 22.71 8.49952V11.2727C22.71 13.0007 21.4724 14.4805 19.7718 14.7861L14.1718 15.7925C12.8282 16.034 11.5976 14.9911 11.6155 13.6261L11.6394 11.7896C11.6429 11.5206 11.8415 11.2942 12.1078 11.2556Z"
        fill="white"
      />
      <path
        d="M24.3867 0.0644894H28.8383V10.4171C28.8383 10.6788 29.062 10.8846 29.3227 10.8628L30.7508 10.7438C30.9826 10.7245 31.1609 10.5308 31.1609 10.2982V-8.58307e-05H35.8061V10.0634C35.8061 11.7056 34.5485 13.0743 32.9121 13.213L26.8245 13.7289C25.5128 13.84 24.3867 12.8054 24.3867 11.489V0.0644894Z"
        fill="white"
      />
      <g opacity="0.399994">
        <g opacity="0.399994">
          <path
            opacity="0.399994"
            d="M37.8071 12.7742V12.7682C37.8054 12.7701 37.805 12.7721 37.8071 12.7742Z"
            fill="white"
          />
        </g>
      </g>
      <path
        d="M44.3072 6.12917H42.2583V2.45179L44.2467 2.39943C44.5018 2.39272 44.7135 2.59536 44.7179 2.85046L44.7663 5.66202C44.7708 5.91872 44.5639 6.12917 44.3072 6.12917ZM46.5271 0.0646172H37.8066V12.7692C37.8825 12.6835 42.3873 12.8389 42.3873 12.8389V8.58082H46.6239C48.1322 8.58082 49.3551 7.358 49.3551 5.84964V2.8926C49.3551 1.33079 48.0889 0.0646172 46.5271 0.0646172Z"
        fill="white"
      />
      <path
        d="M60.6454 4.673e-05H50.71V12.9678C50.71 12.9678 53.7423 13.1613 55.9357 13.3549C58.1293 13.5485 60.9681 13.9355 60.9681 13.9355V11.1613L55.2261 10.7743V8.00005L60.0004 8.19357L59.9358 5.41945L55.2261 5.2904V2.7097L60.71 2.77427L60.6454 4.673e-05Z"
        fill="white"
      />
      <path
        d="M69.161 6.61453C69.161 6.87453 68.9394 7.07937 68.6802 7.05884L66.7095 6.90326L66.774 2.90331L68.7033 2.85115C68.9541 2.84434 69.161 3.04578 69.161 3.29667V6.61453ZM71.3546 10.0645C72.6048 10.1688 73.6771 9.18211 73.6771 7.92745V2.91402C73.6771 1.30465 72.3726 3.62396e-05 70.7632 3.62396e-05H62.2578V14.1292L66.8385 14.9678V9.91406C66.8385 9.79823 66.996 9.76399 67.0442 9.86921L69.6127 15.4839L74.5804 16.4517L71.3546 10.0645Z"
        fill="white"
      />
      <path
        d="M9.55681 33.6383C9.5521 33.989 9.28009 34.278 8.93028 34.3038L6.06455 34.5162V29.613L8.90225 29.5097C9.2889 29.4956 9.60857 29.8081 9.60336 30.1949L9.55681 33.6383ZM9.3757 41.9513C9.36339 42.2468 9.15514 42.4977 8.86701 42.5642L6.00008 43.2259V38.2581L8.7766 37.9047C9.18017 37.8533 9.53298 38.1774 9.51606 38.5838L9.3757 41.9513ZM15.5485 32.8877V29.6667C15.5485 27.5704 14.1071 26.0001 12.0108 26.0001H0V48.4517L11.7083 45.6824C13.9588 45.1501 15.5485 43.1408 15.5485 40.8281V37.6129C15.5485 36.5569 14.7309 35.4921 13.6775 35.4194C14.8926 35.272 15.5485 34.1118 15.5485 32.8877Z"
        fill="white"
      />
      <path
        d="M31.8063 25.8711H18.1934V44.6453C18.1934 44.6453 23.5482 43.8066 25.9998 43.5485C28.4514 43.2905 32.1289 42.9679 32.1289 42.9679V39.7421L24.3224 40.1938V36.5808L30.774 36.1292L30.7095 32.7744L24.3224 33.0324V29.355L31.7417 29.2259L31.8063 25.8711Z"
        fill="white"
      />
      <path
        d="M62.6451 33.4471C62.6451 33.7862 62.3667 34.0591 62.0276 34.0527L59.2902 33.9999L59.2257 29.2258L62.0622 29.3328C62.3876 29.3451 62.6451 29.6124 62.6451 29.9381V33.4471ZM65.8063 37.4839L66.0338 37.5177C67.5111 37.7378 68.8386 36.2033 68.8386 34.7097V29.5592C68.8386 27.5222 66.9399 25.9356 64.9031 25.9356L53.1611 25.871V42.9678L59.4192 43.6129V37.5211C59.4192 37.3825 59.606 37.3379 59.6685 37.4619L62.9676 44L70.0643 44.9033L65.8063 37.4839Z"
        fill="white"
      />
      <path
        d="M79.8067 32.3872L86.0002 32.9033V29.9215C86.0002 27.7119 84.224 25.9129 82.0147 25.8847L74.712 25.7915C72.5845 25.7644 70.8486 27.4877 70.8604 29.6153L70.8819 33.5377C70.8943 35.7449 72.5696 37.5872 74.7657 37.8085L79.1125 38.2463C79.5433 38.2896 79.8713 38.6523 79.8713 39.0852V42.2795C79.8713 42.5712 79.6077 42.792 79.3205 42.7409L77.5928 42.4338C77.2313 42.3695 76.968 42.0552 76.968 41.688V39.9355L71.0325 39.0324L71.0068 41.3527C70.9841 43.397 72.3666 45.19 74.3495 45.6879L82.1227 47.6396C84.0308 48.1187 85.8836 46.6834 85.8965 44.716L85.9357 38.7305C85.9357 36.6716 84.3678 34.9513 82.3175 34.7609L77.5832 34.3212C77.1998 34.2857 76.9084 33.9607 76.9147 33.5756L76.968 30.3657C76.968 30.0925 77.1894 29.871 77.4626 29.871H78.9572C79.4264 29.871 79.8067 30.2514 79.8067 30.7206V32.3872Z"
        fill="white"
      />
      <path
        d="M46.885 33.7068C46.5677 34.1973 45.9413 34.3522 45.9413 34.3522C45.4283 34.2664 45.2235 33.5992 45.4135 32.7547C45.6034 31.9103 46.3157 31.2424 46.8786 31.369C47.4416 31.4957 47.2024 33.2163 46.885 33.7068ZM44.5163 31.742C43.953 31.4948 43.8008 30.5937 44.1292 29.6129C44.4449 28.6697 45.2506 28.0866 45.8139 28.3338C46.3772 28.5809 46.4643 29.7344 46.0647 30.6452C45.6649 31.5561 45.0795 31.9892 44.5163 31.742ZM44.7098 35.2259C44.6453 36.0001 44.175 36.5685 43.0324 36.129C42.1937 35.8065 42.2582 35.4194 41.742 35.1613C41.2491 34.9148 40.1937 35.4194 39.355 34.5807C38.5163 33.742 39.742 32.9033 40.6453 32.5807C41.5485 32.2581 42.4518 30.9678 43.1615 31.1613C43.8711 31.3549 43.7421 32.4516 43.9356 33.0323C44.1292 33.613 44.7744 34.4516 44.7098 35.2259ZM38.8389 30.7743C38.9033 30.1936 39.8066 28.7097 40.3227 28.9678C40.8388 29.2258 40.9678 30.1936 40.5808 30.9678C40.1937 31.742 39.6131 32.1291 39.1614 31.871C39.1614 31.871 38.7743 31.3549 38.8389 30.7743ZM41.5542 28.6322C41.9539 27.7214 42.7345 27.1834 43.2978 27.4305C43.861 27.6776 43.9937 28.6164 43.594 29.5272C43.1944 30.4381 42.4137 30.9761 41.8505 30.729C41.2872 30.4819 41.1546 29.5431 41.5542 28.6322ZM47.9356 25.9356H37.4195C35.4597 25.9356 33.8711 27.5242 33.8711 29.4839V42.9678L40.0001 42.7098V37.742H45.0969V42.7742L51.4841 42.8387V29.4839C51.4841 27.5242 49.8953 25.9356 47.9356 25.9356Z"
        fill="white"
      />
      <path
        d="M24.5488 23.2903H26.0327V23.2514H24.5488V23.2903Z"
        fill="white"
      />
      <path d="M44.46 23.2903H45.9438V23.2514H44.46V23.2903Z" fill="white" />
      <path
        d="M34.3223 23.2581H35.8061V23.2515H34.3223V23.2581Z"
        fill="white"
      />
      <path
        d="M56.6023 23.2903H61.4517V23.2514H56.2197C56.3434 23.2769 56.4712 23.2903 56.6023 23.2903Z"
        fill="white"
      />
      <path
        d="M30.1026 17.3226C30.3308 17.3226 30.5166 17.5083 30.5166 17.7366V18.4032C30.5166 18.9813 30.0463 19.4516 29.4682 19.4516H26.7746V20.9355H27.5667L27.5225 20.8934L28.543 19.8163L29.7118 20.9236C30.9942 20.8005 32.0005 19.7173 32.0005 18.4032V17.7366C32.0005 16.6901 31.1491 15.8387 30.1026 15.8387H25.581C25.0119 15.8387 24.5488 16.3018 24.5488 16.8709V23.2515H26.0328V17.3226H30.1026Z"
        fill="white"
      />
      <path
        d="M29.4678 20.9355H27.5664L30.011 23.2515H32.1686L29.7114 20.9236C29.6312 20.9313 29.55 20.9355 29.4678 20.9355Z"
        fill="white"
      />
      <path
        d="M27.5225 20.8936L27.5668 20.9355H29.4682C29.5504 20.9355 29.6316 20.9313 29.7118 20.9236L28.5429 19.8163L27.5225 20.8936Z"
        fill="white"
      />
      <path
        d="M50.0138 17.3226C50.242 17.3226 50.4278 17.5083 50.4278 17.7366V18.4032C50.4278 18.9813 49.9575 19.4516 49.3794 19.4516H46.6858V20.9355H47.478L47.4336 20.8934L48.4542 19.8163L49.623 20.9236C50.9054 20.8005 51.9116 19.7173 51.9116 18.4032V17.7366C51.9116 16.6901 51.0602 15.8387 50.0138 15.8387H45.4923C44.923 15.8387 44.46 16.3018 44.46 16.8709V23.2515H45.9439V17.3226H50.0138Z"
        fill="white"
      />
      <path
        d="M49.3789 20.9355H47.4775L49.9222 23.2515H52.0798L49.6225 20.9236C49.5423 20.9313 49.4611 20.9355 49.3789 20.9355Z"
        fill="white"
      />
      <path
        d="M47.4336 20.8936L47.4779 20.9355H49.3795C49.4617 20.9355 49.5427 20.9313 49.6229 20.9236L48.4542 19.8163L47.4336 20.8936Z"
        fill="white"
      />
      <path
        d="M35.461 21.6129L35.4418 20.1291L35.8062 20.1243V19.3226C35.8062 18.6314 36.6443 17.3548 37.8385 17.3548H40.2578V20.0666L40.4094 20.0646L40.4287 21.5483L40.2578 21.5505V23.1936H41.7417V16.6129C41.7417 16.2032 41.4095 15.871 40.9997 15.871H37.8385C36.8965 15.871 35.9942 16.2921 35.2979 17.057C34.6871 17.7279 34.3223 18.5748 34.3223 19.3226V23.2515H35.8062V21.6084L35.461 21.6129Z"
        fill="white"
      />
      <path
        d="M35.8066 20.1244V21.6084L40.2582 21.5505V20.0665L35.8066 20.1244Z"
        fill="white"
      />
      <path
        d="M35.4424 20.1291L35.4616 21.6128L35.8068 21.6083V20.1244L35.4424 20.1291Z"
        fill="white"
      />
      <path
        d="M40.4095 20.0646L40.2578 20.0666V21.5505L40.4287 21.5483L40.4095 20.0646Z"
        fill="white"
      />
      <path
        d="M56.6025 21.8065C56.3772 21.8065 56.1939 21.6232 56.1939 21.3979V17.2903H61.4519V15.8065H55.9896C55.284 15.8065 54.71 16.3805 54.71 17.0861V21.3979C54.71 22.3104 55.3591 23.0741 56.2199 23.2515H61.4519V21.8065H56.6025Z"
        fill="white"
      />
      <path
        d="M57.7744 20.3225H60.8712V18.8387H57.7744V20.3225Z"
        fill="white"
      />
    </SVGLogo>
  );
};

export default Logo;
