import { INavigationItem } from '@/components/UI/Navigation/Item/NavigationItem';
import { StyledSocials } from '@/components/Socials/styles';
import Button from '@/components/UI/Button';

export interface Social extends INavigationItem {}

const socials: Social[] = [
  {
    label: 'Twitter',
    url: 'https://twitter.com/SuperRare_Bears',
  },
  {
    label: 'Reddit',
    url: 'https://www.reddit.com/r/SuperRareBears/',
  },
  {
    label: 'Tiktok',
    url: 'https://www.tiktok.com/@superrarebears',
  },
  {
    label: 'Giphy',
    url: 'https://giphy.com/SuperRareBears',
  },
  {
    label: 'Telegram',
    url: 'https://t.me/superrarebears',
  },
  {
    label: 'Medium',
    url: 'https://medium.com/@superrarebears',
  },
  {
    label: 'Discord',
    url: 'https://discord.gg/SuperRareBears',
  },
  {
    label: 'Rarity Sniper',
    url: 'https://raritysniper.com/super-rare-bears',
  },
];

const Socials = () => {
  return (
    <StyledSocials>
      {socials.map((social, key) => (
        <div key={key.toString()}>
          <Button as="a" href={social.url} target="_blank">
            {social.label}
          </Button>
        </div>
      ))}
    </StyledSocials>
  );
};

export default Socials;
