import {
  CloseModal,
  ModalContent,
  ModalOverlay,
  ModalWrapper,
  StyledModal,
} from './styles';
import { observer } from 'mobx-react-lite';
import { inject } from 'mobx-react';
import { IUIStore } from '@/stores/UI';
import { FC, useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import { BearModal } from '@/components/Bear';
import { useRouter } from 'next/router';
import { StoryModal } from '@/components/Story';
import CreateModal from '@/scenes/Stories/CreateModal';
import Greeting from '@/scenes/Stories/CreateModal/Greeting';

interface ModalProps {
  uiStore: IUIStore;
}

const Modal: FC<any> = ({ uiStore }: ModalProps) => {
  const overlayRef = useRef(null);
  const modalRef = useRef(null);

  const router = useRouter();

  const [loaded, setLoaded] = useState(false);

  const closeModal = async () => {
    gsap.to(overlayRef.current, 0.3, {
      opacity: 0,
    });

    gsap.to(modalRef.current, 0.3, {
      opacity: 0,
      onComplete: () => {
        uiStore.closeModal();
      },
    });

    if (uiStore.modalType === 'bear') {
      if (router.query.rel && router.query.rel === 'gallery') {
        return await router.push(
          {
            pathname: '/gallery',
          },
          undefined,
          { scroll: false, shallow: true },
        );
      }

      if (router.pathname === '/bear/[url]') {
        return await router.push(
          {
            pathname: '/',
          },
          undefined,
          { scroll: false, shallow: true },
        );
      }

      return await router.push(
        {
          pathname: router.pathname,
        },
        undefined,
        { scroll: false, shallow: true },
      );
    }

    if (uiStore.modalType === 'story') {
      if (router.pathname === '/stories/[url]') {
        return await router.push(
          {
            pathname: '/stories',
          },
          undefined,
          { scroll: false, shallow: true },
        );
      }
      return await router.push(
        {
          pathname: router.pathname,
        },
        undefined,
        { scroll: false, shallow: true },
      );
    }
  };

  const handleKeyDown = async (e) => {
    if (e.key === 'Escape' || e.keyCode === 27) {
      await closeModal();
    }
  };

  useEffect(() => {
    setLoaded(true);

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (!loaded) return;

    if (uiStore.modalOpen) {
      document.body.style.overflow = 'hidden';

      gsap.fromTo(
        overlayRef.current,
        {
          opacity: 0,
        },
        {
          opacity: 0.8,
        },
      );

      gsap.fromTo(
        modalRef.current,
        {
          opacity: 0,
        },
        {
          opacity: 1,
        },
      );
    } else {
      document.body.style.overflow = 'visible';
    }
  }, [uiStore.modalOpen, loaded]);

  if (!uiStore.modalOpen) return null;

  return (
    <StyledModal>
      <ModalOverlay onClick={closeModal} ref={overlayRef} />
      <ModalWrapper
        small={
          uiStore.modalType === 'createStory' ||
          uiStore.modalType === 'greeting'
        }
        ref={modalRef}
      >
        {uiStore.modalType !== 'greeting' && (
          <CloseModal onClick={closeModal} />
        )}
        <ModalContent>
          {uiStore.modalType === 'bear' && (
            <BearModal identifier={uiStore.modalInstance} />
          )}
          {uiStore.modalType === 'story' && (
            <StoryModal slug={uiStore.modalInstance} />
          )}
          {uiStore.modalType === 'createStory' && <CreateModal />}
          {uiStore.modalType === 'greeting' && <Greeting />}
        </ModalContent>
      </ModalWrapper>
    </StyledModal>
  );
};

export default inject('uiStore')(observer(Modal));
