import Image from 'next/image';
import {
  StoryAttributeContent,
  StoryAttributeLabel,
  StoryAttributes,
  StoryContent,
  StoryFooter,
  StoryImage,
  StoryLink,
  StoryTitle,
  StyledStoryCard,
} from '@/components/Story/Card/styles';
import Badge from '@/components/Badge';
import { FC, forwardRef, useEffect, useRef, useState } from 'react';
import { IStory } from '@/stores/Stories';
import { IUIStore } from '@/stores/UI';
import { inject, observer } from 'mobx-react';

interface StoryCardProps {
  story: IStory;
  compact?: boolean;
  uiStore: IUIStore;
}

const StoryCard: FC<any> = forwardRef(
  ({ story, compact, uiStore }: StoryCardProps, ref) => {
    const cardRef = useRef(null);
    const [height, setHeight] = useState(332);

    const resize = () => {
      if (!cardRef.current) return;
      setHeight(cardRef.current.offsetWidth - 7);
    };

    useEffect(() => {
      if (!cardRef.current) return;

      resize();

      // required because of slick initialization time
      setTimeout(() => {
        resize();
      }, 10);

      window.addEventListener('resize', resize);

      return () => {
        window.removeEventListener('resize', resize);
      };
    }, []);

    const link = `/stories/${story.slug}`;

    const openStory = async (e) => {
      e.preventDefault();

      await uiStore.openModal({ type: 'story', instance: story.slug });

      return window.history.pushState({ scroll: false }, '', link);
    };

    return (
      <StyledStoryCard
        ref={(r) => {
          cardRef.current = r;
          if (ref) {
            // @ts-ignore
            ref.current = r;
          }
        }}
        compact={compact}
      >
        <StoryImage style={{ height }}>
          <a href={`/stories/${story.slug}`} onClick={openStory}>
            <Image
              placeholder="blur"
              blurDataURL="https://raw.githubusercontent.com/ElrondNetwork/assets/master/tokens/SRB-61daf7/logo.png"
              src={story.bear.image}
              alt={story.title}
              layout="fill"
              unoptimized
            />
          </a>
        </StoryImage>
        <StoryContent style={{ height: `calc(100% - ${height}px)` }}>
          <StoryTitle>
            <a onClick={openStory} href={`/stories/${story.slug}`}>
              {story.title}
            </a>
          </StoryTitle>
          <StoryAttributes className="text-small">
            <div>
              <StoryAttributeLabel>Name</StoryAttributeLabel>
              <StoryAttributeContent>{story.bearName}</StoryAttributeContent>
            </div>
            <div>
              <StoryAttributeLabel>Age</StoryAttributeLabel>
              <StoryAttributeContent>{story.bearAge}</StoryAttributeContent>
            </div>
          </StoryAttributes>
          <StoryFooter>
            <StoryLink href={`/stories/${story.slug}`} onClick={openStory}>
              <span>Read story</span>
              <svg
                width="5"
                height="8"
                viewBox="0 0 5 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M4.35355 4.35355C4.54882 4.15829 4.54882 3.84171 4.35355 3.64645L1.17157 0.464466C0.976311 0.269204 0.659729 0.269204 0.464466 0.464466C0.269204 0.659728 0.269204 0.97631 0.464466 1.17157L3.29289 4L0.464466 6.82843C0.269204 7.02369 0.269204 7.34027 0.464466 7.53553C0.659728 7.7308 0.97631 7.7308 1.17157 7.53553L4.35355 4.35355ZM3 4.5L4 4.5L4 3.5L3 3.5L3 4.5Z" />
              </svg>
            </StoryLink>
            <Badge># {story.bear.tokenId}</Badge>
          </StoryFooter>
        </StoryContent>
      </StyledStoryCard>
    );
  },
);

StoryCard.displayName = 'StoryCard';

export default inject('uiStore')(observer(StoryCard));
