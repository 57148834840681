import { SRBTheme } from '@/styles/theme.interface';

const baseSpacing = 8;
export const baseFontSize = 10;

const srbTheme: SRBTheme = {
  colors: {
    primary: {
      first: '#E85143',
      second: '#FADC4B',
    },
    secondary: {
      first: '#8292E0',
      second: '#A9A9A9',
    },
    bg: '#090909',
    text: {
      dark: '#090909',
      light: '#F1F1F1',
      grey: '#A9A9A9',
      white: '#FFFFFF',
    },
    divider: '#3A3A3A',
  },
  spacings: {
    base: `${baseSpacing}px`,
    x2: `${baseSpacing * 2}px`,
    x3: `${baseSpacing * 3}px`,
    x4: `${baseSpacing * 4}px`,
    x5: `${baseSpacing * 5}px`,
    x6: `${baseSpacing * 6}px`,
    x7: `${baseSpacing * 7}px`,
    x8: `${baseSpacing * 8}px`,
    x10: `${baseSpacing * 10}px`,
    x13: `${baseSpacing * 13}px`,
    x14: `${baseSpacing * 14}px`,
    x15: `${baseSpacing * 15}px`,
    x16: `${baseSpacing * 16}px`,
    x18: `${baseSpacing * 18}px`,
    x19: `${baseSpacing * 19}px`,
    x20: `${baseSpacing * 20}px`,
    x25: `${baseSpacing * 25}px`,
  },
  fonts: {
    families: {
      title: `'PPFormula', sans-serif`,
      body: `'Poppins', sans-serif`,
    },
    weights: {
      regular: 400,
      medium: 500,
      bold: 600,
    },
    sizes: {
      mobile: {
        superSmall: `${baseFontSize}px`,
        small: `${baseFontSize * 1.2}px`,
        regular: `${baseFontSize * 1.4}px`,
        medium: `${baseFontSize * 1.6}px`,
        large: `${baseFontSize * 2.4}px`,
        xLarge: `${baseFontSize * 5.8}px`,
        xxLarge: `${baseFontSize * 7.8}px`,
        xxxLarge: `${baseFontSize * 14.6}px`,
      },
      tablet: {
        superSmall: `${baseFontSize}px`,
        small: `${baseFontSize * 1.2}px`,
        regular: `${baseFontSize * 1.6}px`,
        medium: `${baseFontSize * 1.8}px`,
        large: `${baseFontSize * 2.4}px`,
        xLarge: `${baseFontSize * 6.4}px`,
        xxLarge: `${baseFontSize * 11.6}px`,
        xxxLarge: `${baseFontSize * 12.8}px`,
      },
      tabletLandscape: {
        superSmall: `${baseFontSize}px`,
        small: `${baseFontSize * 1.2}px`,
        regular: `${baseFontSize * 1.6}px`,
        medium: `${baseFontSize * 1.8}px`,
        large: `${baseFontSize * 2.4}px`,
        xLarge: `${baseFontSize * 6.4}px`,
        xxLarge: `${baseFontSize * 12.6}px`,
        xxxLarge: `${baseFontSize * 17.2}px`,
      },
      desktop: {
        superSmall: `${baseFontSize}px`,
        small: `${baseFontSize * 1.2}px`,
        regular: `${baseFontSize * 1.6}px`,
        medium: `${baseFontSize * 1.8}px`,
        large: `${baseFontSize * 2.4}px`,
        xLarge: `${baseFontSize * 8}px`,
        xxLarge: `${baseFontSize * 14}px`,
        xxxLarge: `${baseFontSize * 24}px`,
      },
      k2: {
        superSmall: `${baseFontSize * 1.4}px`,
        small: `${baseFontSize * 1.2}px`,
        regular: `${baseFontSize * 1.6}px`,
        medium: `${baseFontSize * 1.8}px`,
        large: `${baseFontSize * 3.2}px`,
        xLarge: `${baseFontSize * 8}px`,
        xxLarge: `${baseFontSize * 14}px`,
        xxxLarge: `${baseFontSize * 24}px`,
      },
    },
    lineHeights: {
      extraSmall: 0.75,
      superSmall: 0.85,
      small: 0.86,
      semiSmall: 0.88,
      less: 0.9,
      regular: 1,
      medium: 1.11,
      semiLarge: 1.25,
      large: 1.33,
      xLarge: 1.38,
      xxLarge: 1.5,
      xxxLarge: 1.6,
    },
  },
};

export { srbTheme };
