import styled, { createGlobalStyle } from 'styled-components';
import { breakpoints } from '@/styles/breakpoints';

const PreloaderGlobalStyles = createGlobalStyle`
  body[data-preloaded='false'] {
    overflow: hidden !important;
  }
`;

const StyledPreloader = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1000;
  height: 100%;
  min-height: 100%;
  background-color: ${({ theme }) => theme.colors.bg};
  pointer-events: none;
`;

const PreloaderLogo = styled.div`
  position: absolute;
  left: 50%;
  top: 16px;
  transform: translateX(-50%);
`;

const Title = styled.div`
  position: absolute;
  line-height: 50px;
  bottom: 3vw;
  left: 50%;
  color: ${({ theme }) => theme.colors.primary.first};
  text-transform: uppercase;
  font-family: 'PPFormula', sans-serif;
  transform-origin: 50% 50%;
  transform: translate(-50%, 5vw) scaleY(0.001);
  white-space: nowrap;
  font-size: 25vw;
  pointer-events: none;
  will-change: transform;

  ${breakpoints.MD} {
    bottom: 0;
    transform: translate(-50%, -5vw) scaleY(0.001);
  }

  ${breakpoints.MDL} {
    transform: translate(-50%, 0) scaleY(0.001);
  }

  ${breakpoints.LG} {
    transform: translate(-50%, 0) scaleY(0.001);
  }

  ${breakpoints.XXLG} {
    transform: translate(-50%, -3vh) scaleY(0.001);
  }
`;

export { StyledPreloader, Title, PreloaderGlobalStyles, PreloaderLogo };
