import styled, { css } from 'styled-components';
import { breakpoints } from '@/styles/breakpoints';
import { NavigationList } from '@/components/UI/Navigation/styles';

const StyledMobileNavigation = styled.div`
  ${breakpoints.MD} {
    display: none;
  }
`;

interface Props {
  opened: boolean;
}

const Burger = styled.button<Props>`
  width: 30px;
  height: 30px;
  text-align: center;
  margin-left: ${({ theme }) => theme.spacings.x3};
  position: relative;
  right: -3px;

  &:before,
  &:after {
    content: '';
    position: absolute;
    width: ${({ theme }) => theme.spacings.x3};
    height: 2px;
    background-color: #fff;
    left: 50%;
    transform: translateX(-50%);
    transition: transform 0.2s ease-in-out;
    will-change: transform;
  }

  &:before {
    top: 10px;
  }

  &:after {
    bottom: 10px;
  }

  ${({ opened }) =>
    opened &&
    css`
      &:before {
        transform: translateX(-50%) translateY(200%) rotate(45deg);
      }

      &:after {
        transform: translateX(-50%) translateY(-200%) rotate(-45deg);
      }
    `}
`;

const Overlay = styled.div<Props>`
  position: fixed;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  top: 86px;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  background: rgba(0, 0, 0, 0.75);
  z-index: 9;

  ${({ opened }) =>
    opened &&
    css`
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
    `}
`;

const Wrapper = styled.div<Props>`
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  position: fixed;
  top: 86px;
  left: -2px;
  right: -2px;
  background: ${({ theme }) => theme.colors.bg};
  padding: ${({ theme }) => theme.spacings.x3} 20px
    ${({ theme }) => theme.spacings.x7};
  border: 2px solid #fff;
  border-top: none;
  border-radius: 0 0 ${({ theme }) => theme.spacings.x7}
    ${({ theme }) => theme.spacings.x7};
  width: calc(100% + 4px);
  z-index: 10;
  transition: opacity 0.1s ease-in-out;
  overflow-y: auto;
  min-height: 500px;
  max-height: calc(100vh - 45px);
  max-height: -webkit-fill-available;

  ${NavigationList} {
    width: 100%;
    display: flex;
    flex-flow: row wrap;

    li {
      width: 100%;
      margin: 0;
      padding: ${({ theme }) => theme.spacings.x4} 0;

      & + li {
        border-top: 2px solid ${({ theme }) => theme.colors.divider};
      }

      a {
        font-size: 58px;
        line-height: 50px;
        font-weight: 900;
        font-family: 'PPFormula', sans-serif;
        width: 100%;
        display: block;
        position: relative;
        top: 6px;
      }
    }
  }

  ${({ opened }) =>
    opened &&
    css`
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
    `};
`;

export { StyledMobileNavigation, Burger, Wrapper, Overlay };
