import styled, { css } from 'styled-components';
import { breakpoints } from '@/styles/breakpoints';

const StyledBearModal = styled.article`
  max-height: 100%;
  height: 100vh;
  min-height: 100vh;
  min-height: -webkit-fill-available;

  ${breakpoints.MD} {
    max-height: calc(477px - 32px);
    min-height: unset;
  }

  ${breakpoints.MDL} {
    max-height: calc(394px - 16px);
  }

  ${breakpoints.LG} {
    max-height: calc(491px - 32px);
  }
`;

const BearModalWrapper = styled.div`
  max-height: 100%;
  height: calc(100vh - 56px);
  display: flex;
  flex-flow: column nowrap;

  ${breakpoints.MD} {
    flex-flow: row wrap;
    align-content: flex-start;
    align-items: flex-start;
    height: calc(100vh - 32px);
  }

  ${breakpoints.MDL} {
    display: grid;
    grid-template-columns: 394px 1fr;
    grid-template-areas:
      'image header'
      'image content'
      'image actions';
  }

  ${breakpoints.LG} {
    display: grid;
    grid-template-columns: 491px 1fr;
    grid-template-areas:
      'image header'
      'image content'
      'image actions';
  }
`;

const BearModalHeader = styled.div`
  display: flex;
  flex-flow: row wrap;
  padding: 0 ${({ theme }) => theme.spacings.x4};

  ${breakpoints.MD} {
    width: 100%;
    order: -1;
  }

  ${breakpoints.MDL} {
    grid-area: header;
    padding: 0 ${({ theme }) => theme.spacings.x2} 0 0;
  }

  ${breakpoints.LG} {
    grid-area: header;
    padding: 0 ${({ theme }) => theme.spacings.x2} 0 0;
  }
`;

const BearModalImage = styled.div`
  padding: 0 ${({ theme }) => theme.spacings.x4};
  width: 100%;

  ${breakpoints.MD} {
    width: 358px;
    padding-right: 0;
  }

  ${breakpoints.MDL} {
    width: 394px;
    grid-area: image;
    padding: 0 ${({ theme }) => theme.spacings.x2};
    grid-row-start: 1;
    grid-row-end: 4;
  }

  ${breakpoints.LG} {
    width: 491px;
    grid-area: image;
    padding: 0 ${({ theme }) => theme.spacings.x4};
    grid-row-start: 1;
    grid-row-end: 4;
  }

  .wrapper {
    position: relative;
    width: 311px;
    height: 311px;
    margin: 0 auto;

    @media (max-height: 670px) {
      width: 250px;
      height: 250px;
    }

    ${breakpoints.MD} {
      width: 342px;
      height: 342px;
      margin: unset;
    }

    ${breakpoints.MDL} {
      width: 362px;
      height: 362px;
    }

    ${breakpoints.LG} {
      width: 427px;
      height: 427px;
    }
  }
`;

const BearModalContent = styled.div`
  padding: 0 ${({ theme }) => theme.spacings.x4};
  overflow-y: auto;
  position: relative;

  ${breakpoints.MD} {
    width: calc(100% - 374px);
    height: calc(342px - 46px);
  }

  ${breakpoints.MDL} {
    grid-area: content;
    padding: 0 ${({ theme }) => theme.spacings.x4} 0 0;
    width: 100%;
    height: calc(362px - 118px);
  }

  ${breakpoints.LG} {
    grid-area: content;
    padding: 0 ${({ theme }) => theme.spacings.x4} 0 0;
    width: 100%;
    height: calc(427px - 118px);
  }
`;

const BearModalTitle = styled.h4`
  && {
    width: 100%;
    margin: ${({ theme }) => theme.spacings.x2} 0 4px;

    ${breakpoints.MD} {
      width: auto;
      margin: 0;
    }
  }
`;

const BearModalToken = styled.div`
  width: 100%;
  display: flex;

  ${breakpoints.MD} {
    width: auto;
    align-self: center;
    margin-left: 8px;
  }

  & > * {
    width: auto;
  }
`;

const RarityScore = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  margin-top: ${({ theme }) => parseInt(theme.spacings.x2) - 2}px;
  padding-bottom: ${({ theme }) => theme.spacings.x2};
  align-items: center;

  ${breakpoints.MD} {
    margin-top: 3px;
  }

  ${breakpoints.MDL} {
    margin-top: 6px;
  }

  ${breakpoints.LG} {
    margin-top: 8px;
  }

  span {
    font-size: 12px;
    line-height: 1;
  }

  & > * {
    width: auto;

    & + * {
      margin-left: ${({ theme }) => theme.spacings.base};
    }
  }
`;

const Traits = styled.div`
  margin: ${({ theme }) => theme.spacings.x2} 0;

  ${breakpoints.MD} {
    margin-top: 0;
  }

  ${breakpoints.MDL} {
    margin-top: 0;
  }
`;

const TraitsTitle = styled.h6`
  && {
    margin: 0 0 9px;
    font-size: 18px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.text.grey};
    text-transform: uppercase;

    ${breakpoints.MD} {
      margin-bottom: ${({ theme }) => theme.spacings.x3};
    }

    ${breakpoints.MDL} {
      margin: ${({ theme }) => theme.spacings.x2} 0
        ${({ theme }) => theme.spacings.base};
    }

    ${breakpoints.LG} {
      margin: ${({ theme }) => theme.spacings.x2} 0
        ${({ theme }) => theme.spacings.x3};
    }
  }
`;

interface TraitsListProps {
  open: boolean;
}

const TraitsList = styled.ul<TraitsListProps>`
  margin: 0;
  padding: 0;
  list-style-type: none;
  overflow: hidden;

  ${breakpoints.MDL} {
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    column-gap: ${({ theme }) => theme.spacings.x7};
  }

  ${breakpoints.LG} {
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    column-gap: ${({ theme }) => theme.spacings.x10};
  }

  ${({ open }) =>
    !open &&
    css`
      height: 113px;

      ${breakpoints.MD} {
        height: 194px;
      }

      ${breakpoints.MDL} {
        height: unset;
      }

      ${breakpoints.LG} {
        height: unset;
      }
    `}
`;

const Trait = styled.li`
  padding: ${({ theme }) => theme.spacings.base} 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;

  & + & {
    border-top: 1px solid ${({ theme }) => theme.colors.divider};
  }

  &:nth-child(2) {
    ${breakpoints.MDL} {
      border: none;
    }

    ${breakpoints.LG} {
      border: none;
    }
  }
`;

const TraitLabel = styled.div`
  font-size: 14px;
  line-height: 21px;

  ${breakpoints.MD} {
    font-size: 16px;
    line-height: 22px;
  }
`;

const TraitValue = styled.div`
  font-size: 12px;
  line-height: 1;
  color: ${({ theme }) => theme.colors.text.grey};
`;

const MoreTraits = styled.button`
  padding: 0;
  margin: 8px 0 0;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  letter-spacing: 0.002em;
  color: ${({ theme }) => theme.colors.primary.second};
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  ${breakpoints.MDL} {
    display: none;
  }

  ${breakpoints.LG} {
    display: none;
  }

  svg {
    margin-left: 4px;
    width: 8px;
    height: 5px;
    transform: translateY(-1px);

    path {
      fill: ${({ theme }) => theme.colors.primary.second};
    }
  }
`;

const Actions = styled.div`
  justify-self: flex-end;
  margin-top: auto;
  display: flex;
  flex-flow: row nowrap;
  padding: ${({ theme }) => theme.spacings.x4};

  ${breakpoints.MD} {
    margin-left: 358px;
    padding-bottom: 0;
    padding-top: ${({ theme }) => theme.spacings.x2};
    margin-top: -46px;
  }

  ${breakpoints.MDL} {
    grid-area: actions;
    margin-left: 0;
    margin-top: 0;
    justify-self: start;
    padding-left: 0;
  }

  ${breakpoints.LG} {
    grid-area: actions;
    margin-left: 0;
    margin-top: 0;
    justify-self: start;
    padding-left: 0;
    padding-top: 5px;
    align-self: end;
  }

  & > * {
    & + * {
      margin-left: ${({ theme }) => theme.spacings.x2};
    }
  }
`;

export {
  StyledBearModal,
  BearModalWrapper,
  BearModalImage,
  BearModalContent,
  BearModalHeader,
  BearModalTitle,
  BearModalToken,
  RarityScore,
  Traits,
  TraitsTitle,
  TraitsList,
  Trait,
  TraitLabel,
  TraitValue,
  Actions,
  MoreTraits,
};
