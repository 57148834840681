import { StyledCopyright } from '@/components/Copyright/styles';

interface CopyrightProps {
  className?: string;
}

const Copyright = ({ className }: CopyrightProps) => {
  return (
    <StyledCopyright className={className}>
      © Super Rare Bears, {new Date().getFullYear()}
    </StyledCopyright>
  );
};

export default Copyright;
