import styled from 'styled-components';
import { breakpoints } from '@/styles/breakpoints';

const StyledSocials = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-content: flex-start;

  a {
    padding-left: ${({ theme }) => parseInt(theme.spacings.x2) - 2}px;
    padding-right: ${({ theme }) => parseInt(theme.spacings.x2) - 2}px;
  }

  & > * {
    margin-bottom: ${({ theme }) => theme.spacings.x2};

    ${breakpoints.MD} {
      margin-bottom: 16px;
    }

    &:not(:last-child) {
      margin-right: ${({ theme }) => theme.spacings.x2};
    }
  }

  ${breakpoints.LG} {
    max-width: 450px;
  }
`;

export { StyledSocials };
