import {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  FC,
  forwardRef,
} from 'react';
import StyledButton from '@/components/UI/Button/styles';

interface BaseButtonProps {
  className?: string;
  disabled?: boolean;
  type?: 'primary' | 'secondary';
  colored?: boolean;
}

export type IOwnProps =
  | ({ as?: 'button' } & BaseButtonProps &
      ButtonHTMLAttributes<HTMLButtonElement>)
  | ({ as?: 'a' } & BaseButtonProps & AnchorHTMLAttributes<HTMLAnchorElement>);

const Button: FC<IOwnProps> = forwardRef(
  (
    {
      as = 'button',
      type = 'secondary',
      colored = false,
      children,
      ...props
    }: IOwnProps,
    ref,
  ) => {
    return (
      <StyledButton ref={ref} type={type} colored={colored} as={as} {...props}>
        <span className="wrap">
          <span className="text">{children}</span>
        </span>
      </StyledButton>
    );
  },
);

Button.displayName = 'Button';

export default Button;
